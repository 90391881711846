@import '../../../mixins.scss';

.side-bar-date-input {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;

  &-label {
    @include label-text;
    margin-bottom: 4px;
  }

  &-content {
    @include field-text;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 12px;
    height: 40px;
  }

  &-content-style-matching-other-date {
    @include field-text;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 12px;
    height: 40px;
    width: 144px;
  }
}
