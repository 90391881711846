$module-navy: #163a5f;
$background-navy: #1e4b75;

.agent-1099-year-end-reporting-page {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 0px;
  height: calc(100vh - 4em);
  gap: 16px;

  .back-button-container {
    margin-bottom: 0px;
  }

  &__header {
    color: white;
    font-family: 'Barlow';
    font-size: 32px;
    font-weight: 600;
    border-radius: 2px;
    letter-spacing: -0.686px;
    padding: 16px;
    background-color: $module-navy;
  }

  &__content {
    border-radius: 5px;
    background-color: $module-navy;
  }

  &__generate-section {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
  }

  &__generation-controls {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: end;

    & .custom_dropdown {
      color: black;
    }
  }

  &__generation-header {
    letter-spacing: -0.686px;
    font-size: 32px;
    font-weight: 600;
    color: white;
    font-family: 'Barlow';
  }
  &__generation-label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.257px;
    margin: 0px;
    color: white;
    font-family: 'Barlow';
  }

  &__button {
    width: 180px;
    height: 42px;
    border-radius: 5px;
    border: 1.5px solid #69dbdb;
    background: #059f9f;
    font-size: 16px;
    color: white;
    font-family: 'Barlow';
  }

  &__results-table {
    flex-grow: 1;
  }

  #agent-1099-year-end-year-dropdown {
    width: 200px;
    color: black;
    padding: 8px 50px 8px 8px;
  }
}
