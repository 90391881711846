$font-family: 'Barlow-SemiBold', 'Barlow';
.split-5754-reporting-page {
  margin: 1em;
  color: rgb(255, 255, 255);

  header {
    font-family: $font-family;
    background: rgb(22, 58, 95);
    margin: 0.5em 0;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 1.75em;
    padding: 0.5em;
    width: 100%;
    letter-spacing: -0.69px;
  }

  .no-results-container {
    display: flex;
    flex-direction: column;
    background: rgb(22, 58, 95);
    width: 100%;
    min-height: 40.5625em;
    border-radius: 0.3125em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    align-items: center;
  }

  .no-results-text {
    margin-top: 2em;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: 'Barlow-Italic', 'Barlow';
    font-weight: normal;
    font-style: italic;
    text-align: center;
    letter-spacing: -0.39px;
  }

  .split-5754-reporting-page-dark-btn {
    width: 95%;
    min-height: 2em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(23, 62, 103, 0.29);
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.34px;
    margin: 0 0.4em;
    color: white;
    background: rgb(30, 75, 117);
    border: 1.5px solid rgb(81, 121, 151);
  }
}
