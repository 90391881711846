$bold-font-family: 'Barlow-SemiBold', 'Barlow';
$default-font-family: 'Barlow-Regular', 'Barlow';

.payment_details_void_options {
  width: 40em;
  border-radius: 5px;
  background-color: white;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  header {
    font-weight: 600;
    font-size: 1.4em;
    font-family: $bold-font-family;
    margin-bottom: 1.5em;
    color: black;
  }

  label {
    margin: 0;
    font-weight: 400;
    line-height: 1em;
    padding: 0 0.5em;
    font-family: $default-font-family;
    * {
      padding-left: 0.25em;
    }
  }

  .cancel-link {
    font-family: $bold-font-family;
    font-weight: 600;
    color: black;
  }

  button {
    font-size: 1.25em;
    width: 11em;
    height: 2.4em;
    font-family: $bold-font-family;

    &.void-button {
      background-color: #d10000;
      border: solid 1.5px #db4a4a;
      border-radius: 5px;
      color: white;
      margin-bottom: 1em;
    }

    &.void-re-issue-button {
      background-color: #059f9f;
      border: solid 1.5px #69dbdb;
      border-radius: 5px;
      color: white;
      margin-bottom: 0.8em;
    }
  }
}
