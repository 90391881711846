.header-tooltip {
  font-size: 1.1em;
  font-weight: 520;
  margin-bottom: 0.25em;
  letter-spacing: 0.4px;
  position: relative;
  --height: 100%;
  
  .tooltip-icon {
    margin-left: 5px;
    margin-bottom: 3px;
    --left: inherit;
  }

  &:hover {
    cursor: pointer;
    .tooltip-message-container {
      visibility: visible;
    }
  }

  .tooltip-message-container {
    font-size: 10px;
    font-weight: 400;
    visibility: hidden;
    color: black;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    line-height: 1em;
    padding: 1em 0.8em;
    display: block;
    position: absolute;
    width: 50%;
    top: calc(var(--height) + 0.25em);
    letter-spacing: 0.4px;
    background-color: white;
    border-radius: 5px;
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: calc(var(--left));
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }
  }
}