@import '../../../../mixins.scss';

.promotional-messages-sidebar-form {
  padding: 0px 16px 16px 16px;

  &__form {
    max-width: 312px;
    margin-top: 24px;

    &__error-text {
      font-size: 16px;
      font-style: italic;
      font-family: 'Barlow-Regular', 'Barlow';
      color: red;
    }

    &__form-item {
      margin-bottom: 15px;
    }

    &__label {
      font-weight: 600;
      letter-spacing: -0.26px;
      font-size: 12px;
      font-family: 'Barlow-Semibold', 'Barlow';
      margin-bottom: 4px;
    }

    &__save-button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;
    }

    &__save-button {
      @include large-button;
      border: 1.5px solid #517997;
      color: white;
      background-color: #1e4b75;
    }
  }
}

#promotion-game-dropdown {
  font-family: 'Barlow';
  padding: 8px 50px 8px 8px;
}
