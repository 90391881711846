$font-family: 'Barlow-SemiBold', 'Barlow';
.check-run-payment-list {
  margin: 1em;
  color: white;

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid white;
    margin: 0;
    padding: 0;
    width: 99%;
    margin-left: 0.5%;
  }

  &_header {
    color: white;
    font-size: 2em;
    padding: 0.5em;
    font-weight: 500;
    margin: 0.5em 0;
    border-radius: 2px;
    background-color: rgb(22, 58, 95);
    * {
      font-family: $font-family;
    }
  }

  &_aggregate-totals-container {
    padding: 1em;
    background-color: rgb(22, 58, 95);
    * {
      font-family: $font-family;
      color: white;
    }
    header {
      font-size: 1.5em;
      font-weight: 500;
    }
  }

  &_aggregate-total-value {
    letter-spacing: -0.39px;
    font-size: 1em;
    display: inline-flex;
    flex-shrink: 0;
    gap: 0.5em;
    :first-child {
      font-weight: 300;
    }
    :last-child {
      font-weight: 600;
    }
  }

  &_left-aligned-values {
    display: inline-flex;
    gap: 1em 1.5em;
    flex-wrap: wrap;
  }

  &_aggregate-total-values-container {
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  &_subheader {
    font-size: 0.5em;
    font-weight: 200;
    letter-spacing: -0.34px;
  }

  // Is this ideal? Probably not. But the styling is available for me to touch so I'm gonna touch it.
  .sr-table-body-text {
    font-weight: 300;
  }
}
