.split-5754-reporting-filter-bar {
  display: flex;
  flex-direction: column;
  color: rgb(255, 255, 255);

  .outer-container {
    display: flex;
    flex-direction: column;
    background: rgb(22, 58, 95);
    border-radius: 0.3125em;
  }

  .title-container {
    display: flex;
    padding-top: .5em;
    padding-left: 1em;
    padding-right: 1em;

    justify-content: space-between;
    align-items: flex-end;
  }

  .title {
    display: flex;
    font-size: 1.75em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.0375em;
  }

  .input-button-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1em;
    align-items: flex-end;
  }

  .select-label-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.3125em;
  }

  .mslc-label {
    font-size: 0.75em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.01625em;
    line-height: 1.125em;
  }

  .custom-select-container {
    position: relative;
  }

  .custom-select-5754 {
    cursor: pointer;
    color: black;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
    width: 13.5625em;
    height: 2.5em;
    font-size: 1em;
    font-weight: normal;
    letter-spacing: -0.3px;
    line-height: 1.25em;
    padding-left: 0.53125em;
  }

  .custom-arrow-5754 {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 2em;
    border-radius: 4px;
    pointer-events: none;
  }
  
  .custom-arrow-5754::after {
    --arrow-size: 0.56em;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  
    /* arrow creation */
    border-left: var(--arrow-size) solid transparent;
    border-right: var(--arrow-size) solid transparent;
    border-top: var(--arrow-size) solid black;
  
    /* arrow position */
    left: 14%;
    top: 38%;
  }

  .mslc-search-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    width: 8.78125em;
    height: 2.5em;
    background: rgb(5, 159, 159);
    border: 0.09375em solid rgb(105, 219, 219);
    border-radius: 0.3125em;
  
    font-size: 1em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.02125em;
  }

  select {
    /* replace default styling( to remove arrow) */
    appearance: none;
  }
}
