.confirm-modal {
  font-family: 'Barlow';
  color: black;
  padding: 48px 116px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    margin-bottom: 24px;
    h1 {
      margin-bottom: 24px;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.514px;
    }
    h2 {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.514px;
    }
  }

  &__error-message {
    text-align: center;
    font-family: 'Barlow';
    color: red;
  }

  &__button {
    color: white;
    font-family: 'Barlow';
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: -0.45px;

    border-radius: 5px;
    border: 1px solid #69dbdb;
    background: #059f9f;
    padding: 12px 70px;
    width: fit-content;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__back-button {
    margin-top: 24px;
    color: #1e4b75;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.429px;
    border: none;
    background-color: white;
    width: fit-content;
    text-align: center;

    &:disabled {
      cursor: not-allowed;
    }
  }
}
