@mixin form-button {
  font-style: normal;
  text-align: center;
  font-size: 16px;
  padding: 10px 0px;
  border-radius: 5px;
  width: 180px;
  font-family: Barlow;
}

.promotions-hourly-report-filter-ui {
  color: #fff;
  background-color: #163a5f;
  border-radius: 4px;
  padding: 16px 16px 8px 16px;
  margin: 16px 0;

  &__container {
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__clear-button {
    @include form-button;
    border: 1.5px solid #517997;
    background: #1e4b75;
  }

  &__form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
  }

  &__form-row {
    font-style: normal;
    letter-spacing: -0.257px;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
  }

  &__generate-button {
    @include form-button;
    border: 1.5px solid #54b3bc;
    background: #059f9f;
  }

  &__header {
    font-family: Barlow;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.6px;
  }

  &__label {
    font-family: Barlow;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.257px;
    line-height: 18px;
    margin-bottom: 4px;
  }
}

#promotion-dropdown {
  font-family: 'Barlow';
  // Overriding predefined padding for dropdown to match design
  padding: 8px 50px 8px 8px;
  min-width: 200px;
  // Override width: 100% to match design and fit longer promotion names
  width: auto;
}
