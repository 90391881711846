$font-family: 'Barlow-SemiBold', 'Barlow';
.generate-btn {
  height: 2em;
  background: rgb(5, 159, 159);
  border: 1.5px solid rgb(105, 219, 219);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 0.8em;
  font-family: $font-family;
  letter-spacing: -0.34px;
  box-sizing: content-box;
  box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
  width: 10em;
}
