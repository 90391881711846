.player-promotions-page {
  display: flex;
  flex-direction: column;

  &__tab-container {
    width: 100%;
    display: flex;
    margin-top: 16px;
    margin-bottom: -4px;
  }
  &__tab {
    background-color: #0c2948;
    min-width: 175px;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    border: none;

    color: white;
    text-align: center;
    font-family: 'Barlow';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.343px;

    padding: 8px 20px 12px 20px;
    margin-right: 4px;

    &:focus {
      border: none;
    }

    &--selected {
      background: #163a5f;
    }
  }
  &__content {
    z-index: 1;
    position: relative;
  }
}
