@mixin landing-page-input {
  input {
    min-width: 100%;
    font-weight: 300;
    padding: 8px 16px;
    font-size: 20px;
    border: none;
    border-bottom: 2px solid #efefef80;
    background: none;
    color: #ffffff;
    font-family: 'Inter';
    color-scheme: dark;
    &:focus {
      border: none;
      border-bottom: 2px solid #efefef80;
      background: none;
      font-weight: 300;
    }
    &::placeholder {
      font-style: italic;
      color: #ffffff;
      font-weight: 300;
      opacity: 0.8;
    }
  }
}

@mixin landing-page-button {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 400;
}

@mixin landing-page-primary-button {
  background-color: #d38517;
  border-color: #d38517;
  color: white;

  &:hover {
    background-color: #e99929;
    border-color: #e99929;
  }

  &:active {
    background-color: #d38517;
    border-color: #d38517;
  }
}

@mixin landing-page-secondary-button {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
  color: #243a5e;

  &:hover {
    background-color: white;
    border-color: white;
    color: #243a5e;
  }

  &:active {
    background-color: #cfcfcf;
    border-color: #cfcfcf;
    color: #243a5e;
  }
}
