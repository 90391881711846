.login-landing-page {
  background-color: #272c33;
  background-size: contain;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__boston-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #272c33;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-image: url('/images/boston.jpg');
    filter: brightness(0.9) contrast(1.2);
    opacity: 0.2;
  }

  &__panel {
    padding: 50px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    border-radius: 12px;
    backdrop-filter: blur(8px) brightness(1.25);
    box-shadow: 0px 0px 12px 0px #0f1821;
    width: clamp(350px, 100%, 500px);
    border-radius: 5px;
  }
}
