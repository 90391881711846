.promotions-date-range-report-filter {
  width: 100%;
  border-radius: 2px;
  background-color: #163a5f;
  color: #fff;
  padding: 12px;

  * {
    font-family: Barlow;
  }

  button {
    color: #fff;
  }

  &__header {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
  }

  &__button-container {
    display: flex;
    gap: 8px;
  }

  &__button {
    width: 180px;
    padding: 10px 0;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: -0.343px;
    &:disabled {
      opacity: 0.5;
    }

    &.primary {
      border: 1.5px solid #54b3bc;
      background: #059f9f;
    }

    &.secondary {
      border: 1.5px solid #517997;
      background: #1e4b75;
      box-shadow: 0px 1px 2px 0px rgba(23, 62, 103, 0.29);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  #promotion-dropdown {
    // Overriding predefined padding for dropdown to match design
    padding: 8px 50px 8px 8px;
    min-width: 200px;
    // Override width: 100% to match design and fit longer promotion names
    width: auto;
  }

  &__label {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 600;
  }

  &__deleted-entries-button {
    font-size: 16px;
    background-color: #163a5f;
    width: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid #fff;
  }

  &__date-controls-container {
    max-width: 200px;
  }
}
