@import '../../../../mixins.scss';

.submit-buttons {
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;

  &__cancel-button {
    @include large-button;
    color: rgb(81, 121, 151);

    &--disabled {
      border-color: rgb(142, 165, 186);
      color: rgb(142, 165, 186);
    }
  }
  &__save-button {
    @include large-button;
    color: white;
    background-color: rgb(30, 75, 117);
    margin-left: 12px;

    &--disabled {
      border-color: rgb(142, 165, 186);
      color: rgb(142, 165, 186);
    }
  }
  &__loading {
    margin-right: 36px;
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
