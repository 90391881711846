.offsets-system-breakdown {
  width: 100%;
  height: auto;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  padding: 1em;
  font-size: .9em;

  &-title-container {
    display: flex;
    flex-direction: column;
  }

  &-title {
    color: rgb(255, 255, 255);
    font-size: 1.375em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.47px;
  }

  &-results-count-container {
    display: flex;
    flex-direction: row;
    gap: .5em;
    align-items: center;
    height: 3em;
  }

  &-results-count-text {
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-family: 'Barlow-SemiBoldItalic', 'Barlow';
    font-weight: 600;
    font-style: italic;
  }

  &-table {
    height: auto;
    background: rgb(22, 58, 95);
  }

  &-table-grid {
    width: 100%;
    min-height: 3em;
    height: auto;
    display: grid;
    align-items: center;
    grid-template-columns: 20% 16% 16% 16% 16% 16% auto;
    color: rgb(255, 255, 255);
    padding-left: 1rem;
  }

  &-table-header {
    background-color: rgb(49, 109, 156);
    border-radius: 5px 5px 0px 0px;
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-family: 'Barlow-Bold', 'Barlow';
    font-weight: bold;
    letter-spacing: -0.34px;
  }

  &-table-row {
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.34px;
    background: rgb(20, 52, 85);

    &:nth-of-type(odd) {
      background: rgb(30, 75, 117);
    }
  }
}