.campaigns-artwork-requirements {
  max-width: 466px;
  font-family: 'Barlow-Regular', 'Barlow';

  > header {
    margin: 16px 0 0 16px;
    font-family: 'Barlow-Semibold', 'Barlow';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.51px;
  }

  &__text {
    margin: 12px 16px 8px 16px;
    font-size: 16px;
    letter-spacing: -0.34px;
  }

  &__guidelines {
    margin: 16px;
    padding: 22px 0;
    max-width: 434px;
    background: rgb(245, 245, 245);
    border-radius: 4px;
    display: flex;
    justify-content: center;

    &__image {
      margin: 22px 0px;
      max-width: 192px;
    }
  }

  &__templates {
    > h1 {
      margin: 8px 0 4px 16px;
      font-size: 18px;
      font-family: 'Barlow-Semibold', 'Barlow';
      font-weight: 600;
      letter-spacing: -0.39px;
    }

    &__item {
      display: flex;
      margin: 12px 16px;

      &__label {
        font-size: 16px;
        letter-spacing: -0.34px;
      }
      &__button {
        margin-left: auto;
        margin-right: 0px;
      }
    }
  }
}
