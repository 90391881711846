@import '../../mixins.scss';

@keyframes darken {
  from {
    background: none;
  }

  to {
    background: rgba(24, 25, 27, 0.5);
  }
}

.generic-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: -5000px;
  top: 0;
  transition: right 0.3s ease-in-out;
  height: 100%;
  overflow: auto;
  z-index: 100;
  background-color: white;
  &--open {
    right: 0;
  }
  &__overlay {
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: darken;
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 16px 0px 16px;
    position: relative;

    &__title {
      font-family: 'Barlow-Regular', 'Barlow';
      font-weight: bold;
      font-size: 22px;
      margin-right: 87px;
    }

    &__close-button {
      @include large-button;
      border: 1.5px solid #517997;
      color: white;
      background-color: #1e4b75;
    }
  }
}
