$font-family: 'Barlow';
.sr-table {
  width: 100%;
  height: auto;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  padding-right: 0.5em;
  padding-left: 0.5em;
  font-size: 0.9em;

  .dark-btn,
  .light-btn {
    width: 95%;
    min-height: 2em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(23, 62, 103, 0.29);
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.34px;
    margin: 0 0.4em;
    color: white;
  }

  .dark-btn {
    background: rgb(30, 75, 117);
    border: 1.5px solid rgb(81, 121, 151);
  }

  .light-btn {
    background: rgb(5, 159, 159);
    border: 1.5px solid rgb(105, 219, 219);
  }

  &-scroll-container {
    overflow-x: auto;
  }

  &-results {
    height: 3em;
    font-size: 0.875em;
    justify-content: space-between;
  }

  &-header {
    min-width: fit-content;
    min-height: 3em;
    height: auto;
    display: grid;
    align-items: center;
    background-color: rgb(49, 109, 156);
    color: rgb(255, 255, 255);
    * {
      font-family: $font-family;
    }
  }

  &-body {
    .sr-table-row:nth-child(odd) {
      padding: 0.2em 0;
      background-color: rgb(20, 52, 85);
    }

    .sr-table-row:nth-child(even) {
      padding: 0.4em 0;
      background-color: rgb(30, 75, 117);
    }
  }

  &-row {
    display: grid;
    min-width: fit-content;
    min-height: 3em;
    height: auto;
    align-items: center;
    white-space: pre-wrap;
  }

  &-body-text {
    width: 95%;
    letter-spacing: -0.39px;
    font-size: 1em;
    word-wrap: break-word;
    color: rgb(255, 255, 255);
    font-family: $font-family;
  }

  &-display-range {
    color: rgb(255, 255, 255);
  }

  &-page-count {
    color: rgb(255, 255, 255);
  }

  &-footer {
    height: 3.8em;
    background: rgb(20, 52, 85);
  }

  .cta-container {
    margin-left: auto;
  }
}

.source-guide-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;

  width: 7.375em;
  height: 2em;
  background: rgb(5, 159, 159);
  border: 1.5px solid rgb(105, 219, 219);
  border-radius: 5px;

  color: rgb(255, 255, 255);
  font-size: 1em;
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.34px;
}
