.future-payment-check-details {
  width: 38.875em;
  background: rgb(12, 41, 72);
  border-radius: 5px;
  padding: 1em;

  .row-container-title {
      color: rgb(255, 255, 255);
      font-size: 1.375em;
      font-family: 'Barlow-SemiBold', 'Barlow';
      font-weight: 600;
      letter-spacing: -0.47px;
    }

  .check-details-controls-container {
      margin-top: 0.28125em;
      display: grid;
      position: relative;
      width: 100%;
      min-height: inherit;
      height: auto;
      border-radius: 0.3125em;
      grid-template-columns: 25% 15% 35% 23%;
      grid-template-rows: auto;
  
      .column-1 {
        grid-column: 1;
        font-family: 'Barlow-Regular', 'Barlow';
        padding-top: 1em;
        color: white;
      }
  
      .column-2 {
        font-family: 'Barlow-SemiBold', 'Barlow';
        padding-top: 1em;
        color: white;
        font-weight: 600;
      }
    }
}