@use '../shared-styles.scss' as shared;

.login-panel {
  &__login-form {
    margin: auto 0;
    width: 100%;
    flex-grow: 1;
    gap: 45px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-group {
      width: 100%;
    }
    @include shared.landing-page-input();
  }
  &__login-button {
    @include shared.landing-page-button();
    @include shared.landing-page-primary-button();
  }
  &__sso-button {
    @include shared.landing-page-button();
    gap: 8px;
    @include shared.landing-page-secondary-button();
  }
}
