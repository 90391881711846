.app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1051;
    /*color: #ffffff !important;*/
}
.app-spinner .spinner-border {
    width: 3rem;
    height: 3rem;
}