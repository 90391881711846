.promotion-campaign-tool-tip {
  &__icon {
    margin-left: 4px;
    display: flex;
  }

  &__text {
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
