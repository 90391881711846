$font-family: 'Barlow-SemiBold', 'Barlow';
.payment-status-ui {
  display: flex;
  align-items: center;
  font-family: $font-family;
  letter-spacing: -0.34px;

  .status-icon-container {
    margin-left: 5px;
    position: relative;
    line-height: 12px;
    &:hover {
      .notes-tooltip {
        visibility: visible;
      }
    }
    .notes-tooltip {
      --width: 15em;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      visibility: hidden;
      line-height: 1em;
      margin-top: 10px;
      padding: 1em;
      display: block;
      position: absolute;
      top: 100%;
      width: var(--width);
      left: 50%;
      background-color: white;
      border-radius: 5px;
      z-index: 1000;

      &.left {
        margin-left: calc(-1 * var(--width) / 5);
        &::after {
          left: calc(var(--width) / 5);
        }
      }

      &.right {
        margin-left: calc(-4 * var(--width) / 5);
        &::after {
          left: calc(4 * var(--width) / 5);
        }
      }

      * {
        color: black;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent white transparent;
      }

      .title {
        font-weight: 600;
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }

      .title,
      .content {
        font-family: $font-family;
        text-overflow: ellipsis;
      }

      .content {
        max-height: 8em;
        display: inline-block;
      }
    }
  }
}
