@import '../../../mixins.scss';

$border-radius: 5px;

.generic-table {
  &-content {
    background: rgb(22, 58, 95);
    padding: 16px 8px 0px 8px;
    border-radius: $border-radius;
  }

  width: 100%;
  color: rgb(255, 255, 255);
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;

  tbody:nth-child(odd) {
    background-color: rgb(20, 52, 85);
  }

  tbody:nth-child(even) {
    background-color: rgb(29, 69, 111);
  }

  tbody:last-child {
    border-radius: 0px 0px $border-radius $border-radius;

    tr:last-child {
      border-radius: 0px 0px $border-radius $border-radius;
      td {
        &:first-child {
          border-radius: 0px 0px 0px $border-radius;
        }

        &:last-child {
          border-radius: 0px 0px $border-radius 0px;
        }
      }
    }
  }

  &-header {
    background-color: rgb(49, 109, 156);
    border-radius: $border-radius $border-radius 0px 0px;
    overflow: hidden;
    font-size: 16px;
    letter-spacing: -0.34px;
    width: 100%;

    &__cell {
      text-align: left;
      background-color: rgb(49, 109, 156);
      border: none;
      color: white;
      font-family: 'Barlow-Bold', 'Barlow';

      padding: 14px 8px;

      &:first-child {
        padding-left: 16px;
      }

      &:first-child {
        border-radius: $border-radius 0px 0px 0px;
      }

      &:last-child {
        border-radius: 0px $border-radius 0px 0px;
      }
    }
  }

  &-footer {
    max-width: 100%;
    background: rgb(22, 58, 95);
    color: rgb(255, 255, 255);
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0px 0px $border-radius $border-radius;
    padding-right: 8px;

    &__page-label {
      font-family: 'Barlow-Bold', 'Barlow';
      font-size: 16px;
      font-weight: bold;
      text-align: right;
      margin-right: 10px;
    }
  }
}
