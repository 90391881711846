.label-and-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: .2em;
  padding-left: .2em;
  padding-right: 0.3em;

  .label {
    margin-bottom: 0.1875em;
    color: white;
    font-size: 0.625em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
  }

  .input-box {
    height: 2.5em;
    width: 99.5%;
    background: rgb(255, 255, 255);
    border-radius: 0.25em;
    border: 0.0625em solid rgb(107, 107, 107);
    box-shadow: inset 0em 0.0625em 0.1875em 0em rgba(15, 24, 33, 0.2);
    padding: .5em;
    appearance: auto; /*To display the small down-arrow on drop-down list*/
  }
}