$border-radius: 5px;

.winners-management-list-row-action-menu {
  position: relative;
  width: 112px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__icon {
    width: 36px;
    margin: 8px;
    cursor: pointer;
    padding: 0;
    border: 2px solid transparent;
    box-sizing: border-box;
    background: none;
  }

  &__overlay {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0px;
  }

  &__popup {
    z-index: 100;
    background-color: white;
    border-radius: $border-radius;

    position: absolute;
    right: 8px;

    &--closed {
      transform: scale(0);
      opacity: 0;
      visibility: hidden;
    }

    &__menu-item {
      width: 100%;
      height: 32px;
      border: none;

      display: block;
      padding: 0 8px;

      text-align: left;
      font-size: 14px;
      font-family: 'Barlow-Bold', 'Barlow';
      font-weight: bold;
      color: black;
      text-wrap: nowrap;

      cursor: pointer;

      &:first-child {
        border-radius: $border-radius $border-radius 0 0;
      }

      &:last-child {
        border-radius: 0 0 $border-radius $border-radius;
      }

      &--enabled {
        &:hover {
          background-color: rgb(210, 210, 210);
        }
        &:focus {
          background-color: rgb(210, 210, 210);
          border: none;
          box-shadow: none;
        }
      }

      &--disabled {
        color: rgb(138, 140, 143);
      }
    }
  }
}
