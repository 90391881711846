.app-table {
    color: #FFFFFF;
}

.table-header {
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    /*text-transform: uppercase;*/
}
table td, table th {
    overflow-wrap: anywhere;
}

table {
    width: 100%;
}
table th {
    border: 1px solid #005288;
    background-color: #FFFFFF;
    color: #005288;
}
/*table td {*/
/*    border: 1px solid #FFFFFF;*/
/*}*/
/*table tr th:first-child {*/
/*    border-left: 0;*/
/*}*/
/*table tr th:last-child {*/
/*    border-right: 0;*/
/*}*/
/*table tr:first-child td {*/
/*    border-top: 0;*/
/*}*/
/*table tr:first-child th {*/
/*    border-top: 0;*/
/*}*/
/*table tr:last-child td {*/
/*    border-bottom: 0;*/
/*}*/
/*table tr td:first-child {*/
/*    border-left: 0;*/
/*}*/
/*table tr td:last-child {*/
/*    border-right: 0;*/
/*}*/
table tr th {
    border-left: 0;
    border-right: 0;
}
table tr td {
    border-left: 0;
    border-right: 0;
}

.my-table {
    max-height: 300px;
    overflow-y: auto;
    table-layout:fixed;
}
.table-header-dropdown {
    margin-bottom: 5px;
}
.table-dropdown-label {
    color: #FFFFFF;
    margin: 0 5px 5px;
}