.contact-checklist {
  font-family: 'Barlow-Regular', 'Barlow';
  color: black;
  padding: 44px;

  header {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.514px;
    margin-bottom: 14px;
    width: fit-content;
  }

  &-winner-info {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      margin-bottom: 8px;
    }

    &__label {
      font-size: 16px;
      font-weight: 600;
      margin-right: 8px;
    }

    &__data {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
    }
  }

  &-contact-info {
    display: flex;
    flex-direction: column;

    font-size: 16px;
    font-weight: 600;

    > div {
      display: flex;
      margin-bottom: 20px;
    }

    &__checkbox-container {
      width: fit-content;
    }

    &__error {
      color: red;
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      text-align: center;
    }
  }

  &__back-button {
    margin-top: 4px;
    color: #1e4b75;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.429px;
    border: none;
    background-color: white;
    width: 100%;
    text-align: center;
  }
}
