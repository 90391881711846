@import '../../../../../../mixins.scss';

.scan-to-enter-progress-details-modal {
  padding: 34px 28px;
  width: 850px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  & .generic-table-content {
    padding: 0;
  }

  &__header {
    color: #1e1e1e;
    font-family: 'Barlow';
    font-size: 22px;
    font-weight: 600;
    line-height: 150%; /* 33px */
    letter-spacing: -0.418px;

    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    max-height: 500px;
    overflow-y: scroll;
  }

  &__buttons {
    align-self: center;
    display: flex;
    gap: 12px;
  }

  &__close-button {
    @include large-turquoise-button;
  }

  &__go-back-button {
    @include large-navy-button;
  }
}

.scan-to-enter-progress-rule-section {
  padding: 8px;
  border-radius: 5px;
  background: #eff6ff;

  &__details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    & > h3 {
      color: #1e1e1e;
      font-family: 'Barlow';
      font-size: 16px;
      font-weight: 600;
      line-height: 150%; /* 24px */
      letter-spacing: -0.304px;

      margin: 0;
      padding: 0;
    }
  }

  &__button {
    border-radius: 5px;
    border: 1.5px solid #316d9c;
    background-color: #1e4b75;
    padding: 4px 12px;

    color: #fff;
    text-align: center;
    font-family: 'Barlow';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.343px;
    cursor: pointer;
  }

  &__no-scans {
    color: black;
  }
}
