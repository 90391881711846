.check-run-queue-all-future-payments {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  font-size: 0.9em;

  .aggregate-totals-section {
    margin-top: 1em;
    margin-inline: 0.5em;

    padding: 0 0.5em;
    padding-bottom: 1em;
    border-bottom: 1px solid white;
  }
}
