.widget-card {
    /*cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: #005288;
    height: 100px;
    width: 400px;
    margin-bottom: 10px;
    margin-left: 40px;
    align-items: center;
    box-shadow: 0 0 13px #005288;*/
    position: relative;
    cursor: pointer;
    justify-content: center;
    margin-top: 10px;
    margin-left: 20px;
    align-items: center;
    width: 175px;
    height: 64px;
    border-radius: 5px;
    background: #1e4b75;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
    font-family: Barlow;
    font-weight: 400;
}
.widget-card:hover {
    position: relative;
    cursor: pointer;
    justify-content: center;
    margin-top: 10px;
    margin-left: 20px;
    align-items: center;
    width: 175px;
    height: 64px;
    border-radius: 5px;
    background: #359FB1;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
    font-family: Barlow;
    font-weight: 400;
}
.widget-card-icon img {
    position: absolute;
    width: 27px;
    height: 30px;
    right: 5px;
    margin-top: 3px;
}
.widget-card-label {
    position: absolute;
    font-size: 14px;
    color: #FFFFFF;
    bottom: 10px;
    margin-left: 12px;
    /*text-transform: uppercase;*/
}
.widget-card-data {   
    position: absolute; 
    font-size: 25px;
    color: #FFFFFF;
    margin-left: 12px;
}