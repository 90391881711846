.reject-claim-container {
    margin: 2px 0;
    position: relative;
}

.reject-claim-panel {
    padding: 10px;
    background-color: #173E67;
    border-radius: 4px;
}
.reject-claim-container .reject-link {
    color: #FFFFFF;
    cursor: pointer !important;
    border-bottom: 1px solid #FFFFFF;
}