.scan-to-enter-progress {
  margin: 0 0 16px 0;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  padding: 20px 18px 20px 16px;
  color: white;

  & .generic-table-content {
    padding: 0;
  }
}
