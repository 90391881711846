.confirmation-dialog-main-container {
  position: fixed;
  top: 0em;
  left: 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 24, 33, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.confirmation-dialog-container {
  width: 45.375em;
  height: 19.0625em;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3em;
  padding-bottom: 2em;
  border-radius: 0.3125em;
}

.confirmation-dialog-message {
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 600;
  color: rgb(0, 0, 0) !important;
  font-size: 1.375em;
  font-weight: 600;
  letter-spacing: -0.031875em;
}

.confirmation-dialog-sub-message {
  color: rgb(0,0,0) !important;
  margin-top: 2em;
}

.confirmation-dialog-yes-button {
  background: rgb(5, 159, 159);
  margin-top: 3em;
  border-radius: 0.3125em;
  border: 0.0625em solid rgb(105, 219, 219);

  width: 12.75em;
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.confirmation-dialog-yes-button-text {
/*    width: 10.875em;
  height: 1.5625em;*/
  color: rgb(255, 255, 255);
  font-size: 1.3125em;
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.45px;
}

.confirmation-dialog-no-button {
  margin-top: 1.5em;
  color: rgb(30, 75, 117) !important;
  font-size: 1.125em;
  font-weight: 600;
  letter-spacing: -0.026875em;
}

.void-button {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 1em;


  width: 12.75em;
  height: 3em;
  background: rgb(30, 75, 117);
  border: 1.5px solid rgb(81, 121, 151);
  border-radius: 5px;
}

.void-button-text {
/*    width: 2.9375em;
  height: 1.5625em;*/
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.45px;
}