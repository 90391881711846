@use '../shared-styles' as shared;

.mfa-ui {
  &__mfa-form {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: auto 0;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    @include shared.landing-page-input();
  }
  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
  }
  &__primary-button {
    @include shared.landing-page-button();
    @include shared.landing-page-primary-button();
  }

  &__secondary-button {
    @include shared.landing-page-button();
    @include shared.landing-page-secondary-button();
  }
}
