.promotion-drawing-tooltip {
  &__tooltip {
    display: flex;
    flex-direction: column;
    width: fit-content;
    white-space: nowrap;

    word-wrap: normal;
    color: black;
    font-family: 'Barlow';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.3px;
  }

  &__tooltip-header {
    font-family: 'Barlow';
    font-weight: 600;
  }

  &-ui {
    &__drawing {
      display: flex;
      flex-direction: column;
      width: fit-content;

      &-text {
        color: #fff;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.343px;

        &--italic {
          font-style: italic;
        }
      }
    }
  }
}
