.winner-management-winner-form-ui-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin: 10px;
  overflow: auto;
  font-family: 'Barlow';

  &__input {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: white;
    flex-grow: 1;
  }

  &__input-label {
    font-size: 12px;
  }

  &--small-input {
    max-width: 100px;
  }

  &--medium-input {
    max-width: 140px;
  }

  &__input-box {
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    height: 40px;
    font-size: 16px;
  }

  &__delete-button {
    border: none;
    background: none;
    margin-top: 33px;
  }

  &__error {
    background-color: #db4a4a;
    border-radius: 2px;
    font-size: 12px;
    padding: 4px;
    margin-top: 4px;
    margin-left: 4px;
  }

  &__error-container {
    display: flex;
    flex-direction: column;
  }

  &__error-arrow {
    color: #db4a4a;
  }
}
