.reject-detail-container {
    margin: 2px 0;
    position: relative;
    color: #FFFFFF;
    font-size: 13px;
}
.reject-detail-panel {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #173E67;
    border-radius: 4px;
    margin-bottom: 10px;
}
.reject-detail-top-row {
    display: flex;
    justify-content: space-between;
}
.reject-detail-container .view-pdf-button {
    float: right;
    background-color: #173E67;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.reject-detail-container .reject-detail-link {
    color: #FFFFFF;
    cursor: pointer !important;
    border-bottom: 1px solid #FFFFFF;
}
.reject-detail-container .no-data-message {
    font-size: 1rem;
}
.currency-column:before {
    content: '$';
}
.modal-unmasked-detail {
    color: #FFFFFF !important;
}