@import '../../../../../../mixins.scss';

.scan-to-enter-progress-filter-form {
  display: flex;
  margin-bottom: 16px;
  gap: 8px;

  &-container {
    width: 100%;
  }

  &__header {
    color: white;
    font-family: 'Barlow';
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.6px;
    margin-top: 0;
  }

  & > .date-and-time-input {
    margin-bottom: 0;
    margin-top: 0;
    width: 175px;
    min-width: 130px;
  }

  &__toggle {
    width: 180px;
    height: 42px;
    margin-top: 18px;

    border-radius: 6px;
    border: 1.5px solid #fff;
    background: rgb(22, 58, 95);

    color: white;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.3px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }

  &__search-button {
    @include large-turquoise-button;
    margin-top: 18px;
    height: 42px;
  }

  &__clear-button {
    @include large-navy-button;
    margin-top: 18px;
    height: 42px;
  }
}
