.full-container{
    display: flex;
    flex-direction: column;
}
.container-left{
    display: flex;
    float: left;
    flex-direction: column; 
    margin-left: 1%;
    width: 48%;
    height: 360px;
    margin-top: 12px;
}

.container-right{
    display: flex;
    float: left;
    flex-direction: column;
    margin-left: 15px;
    width: 48%;
    height: 360px;
    margin-top: 12px;
}

.report-header {
    height: 147px;
    width: 98%;
    margin-left: 1%;
}

.new-claims{
    height: 147px;
}

.returned-payments{
    height: 99px;
}

.new-payments{
    height: 186px;
    margin-top: 12px;
}

.payment-retries{
    height: 130px;
    margin-top: 12px;
}
.itemized-transactions{
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 20px;
}
.check-circle {
    height: 18px;
    width: 18px;
    background: rgb(80, 185, 106);
    border-radius: 50%;
    margin-top: 10px;
}
.check-img {
    position: absolute;
    height: 18px;
    width: 18px;
}
.tile-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #163a5f;
    font-family: Barlow;
    font-weight: 400;
    height: 100%;
    width: 100%;
}
.tile-card-contents {
    margin-left: 16px;
}
.tile-card-label {
    font-size: 22px;
    color: #FFFFFF;
    font-family: Barlow;
    font-weight: 500;
    margin-left: 16px;
    margin-top: 16px;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
    /*text-transform: uppercase;*/
}
.tile-card-title-label {
    font-size: 28px;
    color: #FFFFFF;
    font-family: Barlow;
    font-weight: 600;
    margin-left: 16px;
    margin-top: 16px;
    letter-spacing: -0.476px;
    margin-bottom: 15px;
    /*text-transform: uppercase;*/
}
.tile-card-data {
   display: flex;
   flex-direction: row;
   margin-left: 16px;
}
.tile-card-data-label{
    width: 250px;
    height: 22px;    
    color: rgb(240,240,240);
    font-size: 18px;
    font-family: Barlow;
    font-weight: normal;
    letter-spacing: -0.39px;
    margin-top: 5px;
}
.tile-card-data-value{
    width: 108px;
    height: 22px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: Barlow;
    font-weight: 600;
    text-align: right;
    letter-spacing: -0.39px;
    margin-top: 5px;
    margin-right: 8px;
}
.tile-card-note-label {
    font-size: 12px;
    color: #FFFFFF;
    font-family: Barlow;
    font-weight: 400;
    margin-left: 16px;
    letter-spacing: -0.6px;
    /*text-transform: uppercase;*/
}

.date-picker-btn {
    justify-content: center;
    min-height: 38px;
    min-width: 161px;
    background-color: #059f9f;
    border-color: #FFFFFF;
    color: #FFFFFF;
    align-items: center;
    /*text-align: center;*/
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
}
.recon-table {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #173E67;
    border-radius: 4px;
}
.table-container{
    display: table;
    margin-left: 15px;
    margin-right: 15px;    
    border-radius: 4px;
}
.tile-card hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #fff;
    margin: 1em 0;
    padding: 0;
    width: 360px;
    margin-left: 16px;
}
.recon-link {
    cursor: pointer;
    color: white;
    text-decoration: underline;
    font-weight: bold;
}