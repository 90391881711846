$border-radius: 5px;

.winners-management-list {
  width: 100%;
  background: rgb(22, 58, 95);
  color: white;
  border-collapse: collapse;

  tbody:nth-child(odd) {
    background-color: rgb(20, 52, 85);
  }

  tbody:nth-child(even) {
    background-color: rgb(29, 69, 111);
  }

  &-header {
    background-color: rgb(49, 109, 156);
    border-radius: $border-radius $border-radius 0px 0px;
    overflow: hidden;
    width: 100%;

    font-family: 'Barlow';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.343px;

    &__cell {
      text-align: left;
      word-wrap: normal;
      color: white;
      background-color: rgb(49, 109, 156);
      padding: 14px 8px 14px 8px;

      &:first-child {
        border-top-left-radius: $border-radius;
        padding: 14px 16px;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }
  }

  &-body {
    text-align: left;

    &__message-bar {
      background-color: #6b6b6b;
      border-radius: 5px;
      margin: 8px 16px -6px 16px;
      padding: 4px 8px;
      font-size: 14px;
      font-family: 'Barlow';
      font-weight: 600;
      letter-spacing: -0.3px;
      max-height: 24px;
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      display: flex;
    }

    &__row {
      font-family: 'Barlow';
      font-size: 16px;
      letter-spacing: -0.343px;
    }

    &__cell {
      font-weight: 400;
      word-wrap: normal;
      padding: 14px 8px 14px 8px;

      &:first-child {
        padding: 14px 16px;
      }

      &--link {
        padding: 14px 8px 14px 8px;
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}
