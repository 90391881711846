.document-image-outer-container
{
   position:relative; 
   width:100%;
   height:7.3vmax;
   border-radius: 5px;   
   background-color: rgb(22, 58, 95);;
   display:flex;
   flex-direction: column;
   justify-items: baseline;
   justify-content: flex-start;
   align-items: center;
   font-size:.71vmax;  
   padding-left:1em;
   padding-right:1em;
   padding-top:1em;  
   /* margin-left: 4.27vw; */
   /* margin-top: 2vh; */
}

.player-document-info-players-document-label-container
{
    display:flex;
    flex-direction: row; 
    justify-content:space-between;
    align-items:center;   
    min-height:2.625em;
    width:100%;
    
     
}

.player-document-info-players-document-text
{
    color: rgb(255, 255, 255);
    font-family: inherit;
    font-size: 1.375em;
    margin-right:1.2em;
    
   
}

.player-document-info-players-document-selection-text
{
    color: rgb(255, 255, 255);
    font-family: inherit;
    font-size: 1.12em;
    margin-right:1.3em;        
}

.player-document-info-players-document-selection
{    
    width: 42.921%;
    font-family: inherit;
    padding:.3em;
    font-size:1.12em;
    min-height:2.12em;
       
}

.player-document-info-players-document-details-labels-container
{
    width:100%;
    margin-top:1em;
    color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: 17% 14% 18% auto;
    grid-template-rows: auto; 
    /* justify-content: flex-start; */
    align-items:center;
    justify-content: flex-start;  
    
    background-color: inherit;       
}



.player-document-info-players-document-details-labels-text
{    
    font-size:1em;
    opacity:0.8;
    font-weight:200;
}
.document-image-outer-container-hr
{
    margin-top: .1em;
    margin-right: 1.2em;
    width: 100%;
    background-color:white;
    height: 1px;
}
.player-document-info-players-document-details-content-container
{
   
    position: relative;
    width:100%;
    height:2em;
    color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: 18% 12% 54% 15%; 
    /* justify-content: flex-start; */
    align-items:center;
    justify-content: flex-start;  
    padding-left:.2em; 
    background-color: inherit;
   margin-top:.3em;
    margin-bottom: 0.5em;
}

.player-document-info-players-document-details-content-text
{
    font-size:1em;
}
.player-document-info-players-document-details-content-select-and-button-container{
   min-height:100%;
   min-width:58.4%;
   display:flex;
   flex-direction:row;
   align-items: center;
   justify-content: space-between;  
}
.player-document-info-players-document-details-content-select
{    
    border-radius: 4px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size:1em;
}

.player-document-info-players-document-details-content-delete
{    
    width: 5.774%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.1em;
    border-radius: 3px;
    margin-right: .3em;
}

.player-document-info-players-document-details-content-button-hide-show
{
    background: rgb(5, 159, 159);
    border-radius: 5px;
    border: 1.5px solid rgb(105, 219, 219);
    width: 6.083em;
    font-size: 1.125em;
    display: flex;
    margin-right:1em;
    min-height:2.2em;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
    cursor:pointer;

}

.document-image-container
{
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;  
   margin-top: 2%;
   width: 94.778%; 
   height: 23.125em; 
   background-color: #6791B1;
   margin-bottom:1.75em;
}

.document-image-src-container
{    
    width: 100%;
    height:100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: transperent;
    z-index: 0;
        
}

.document-image-src-controll-container
{
    position: absolute; 
    top: 83%;
    left: 73%;     
    display: flex;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    height: 2em;
    width: 25%;
    font-size: 1.8em;
    z-index: 100;
    color:#097969;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 5px solid rgb(23, 62, 103);
}

.rotateimg0 {
    -webkit-transform:rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    width:100%;
    height:100%;
  }
.rotateimg90 {
    -webkit-transform:rotate(90deg);
    -moz-transform: rotate(90deg) scale(39%,100%);
    -ms-transform: rotate(90deg) scale(39%,100%);
    -o-transform: rotate(90deg) scale(39%,100%);
    transform: rotate(90deg) scale(39%,100%);
    width:100%;
    height:100%;
  }
.rotateimg180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    width:100%;
    height:100%;
  }
  .rotateimg270 {
    -webkit-transform:rotate(270deg) scale(39%,100%);
    -moz-transform: rotate(270deg) scale(39%,100%);
    -ms-transform: rotate(270deg) scale(39%,100%);
    -o-transform: rotate(270deg) scale(39%,100%);
    transform: rotate(270deg) scale(39%,100%);
    width:100%;
    height:100%;
  }