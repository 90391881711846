.winners-management-weekly-run {
  &__header {
    background-color: #163a5f;
    border-radius: 5px;

    font-family: 'Barlow';
    color: white;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    padding: 16px;
    margin: 16px 0 8px 0;
    overflow-wrap: break-word;
  }

  &-content {
    background-color: #163a5f;
    border-radius: 5px;
    padding: 16px 8px;

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
    }

    &__title {
      display: flex;
      flex-direction: column;
      color: #fff;
      font-family: 'Barlow';
      font-size: 22px;
      font-weight: 700;
      line-height: normal;
    }

    &__disclaimer {
      color: #fff;
      font-family: 'Barlow';
      font-size: 22px;
      font-weight: 700;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    &__download-csv-button {
      color: white;
      text-align: center;
      font-family: 'Barlow';
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.343px;

      padding: 10px 16px;
      border-radius: 5px;
      border: 2px solid #517997;
      background: #1e4b75;
      min-width: 130px;
      height: 44px;

      box-shadow: 0px 1px 2px 0px rgba(23, 62, 103, 0.29);
    }
  }
}
