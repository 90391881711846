$module-navy: #163a5f;
$background-navy: #1e4b75;
@mixin cta-button {
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Barlow';
  color: white;
  padding: 8px 16px;
  font-weight: 500;
  margin: 8px 0px;
  &:disabled {
    opacity: 0.5;
  }
}
.agent-1099-print-history-page-ui {
  * {
    font-family: 'Barlow';
  }
  .back-button-container {
    margin-bottom: 8px;
  }
  background-color: $background-navy;
  margin: 16px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 4em);
  &__header {
    padding: 16px;
    border-radius: 2px;
    background-color: $module-navy;
    color: white;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.686px;
  }
  &__content-container {
    background-color: $module-navy;
    flex-grow: 1;
    border-radius: 5px;
  }
  &__cta-button-primary {
    @include cta-button();
    border: 1.5px solid #69dbdb;
    background: #059f9f;
  }
  &__cta-button-secondary {
    @include cta-button();
    border: 1.5px solid #316d9c;
    background: #1d456f;
  }
  &__cta-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .generic-table {
    white-space: pre-wrap;
  }
  .generic-table-row {
    height: 48px;
  }
}
