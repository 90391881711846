.claim-details-container {
    margin: 2px 0;
    position: relative;
}
.claim-details-child {
    /*min-width: 100px;*/
    margin: 0;
    position: relative;
    top: 1%;
    right: 1%;
    left: 1%;
    min-height: 280px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    /*box-shadow: 0 0 18px #005288;*/
    /*background-color: #005288;*/
}
.claim-details-panel {
    padding: 10px 15px;
    background-color: #173E67;
    /*position: absolute;*/
    top: 15px; bottom: 15px; left: 20px; right: 20px;
}
.claim-details-panel input[type=text] {
    min-width: 100px !important;
    max-width: 120px !important;
    margin-bottom: 2px !important;
    font-size: 13px !important;
    height: 25px !important;
    padding-left: 0;
}
.claim-details-panel select {
    /*min-width: 100px !important;*/
    min-width: 120px !important;
    font-size: 14px !important;
    padding: 2px;
}
.claim-details-panel .app-textbox {
    margin-bottom: 0 !important;
}
.claim-details-label {
    color: #ddecef;
    font-size: 13px;
    font-weight: bold;
    /*text-transform: uppercase;*/
}
.claim-details-value {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: normal;
    /*text-transform: uppercase;*/
}
.claim-details-panel .row {
    margin-top: 1%;

}
.claim-details-btn {
    margin-top: 10px;
    justify-content: center;
    min-height: 30px;
    min-width: 110px;
    background-color: #1E4B75;
    border-color: #1E4B75;
    color: #FFFFFF;
    align-items: center;
    /*text-align: center;*/
    margin-right: 10px;
    box-shadow: 0 0 2px #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
}
.claim-details-btn:hover {
    background-color: #359FB1;
    border-color: #359FB1;
}
.claim-invalid-email {
    color: red;
    font-size: 13px;
}
.separator {
    border: 1px solid #FFFFFF;
}
.button-column {
    text-align: center;
}
.button-column .claim-details-btn {
    min-width: 150px;
}
.claim-button-row {
    margin-bottom: 1%;
}

.claim-details-container .success-message {
    min-height: auto !important;
    top: -12% !important;
}
.verify-claim-link, .verify-claim-link:hover {
    color: #FFFFFF !important;
}

.search-table {
    margin-top: 2%;
    padding-left: 0;
    width: 100%;
}