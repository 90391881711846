.window-container {

    position:fixed;
    top:0px;
    left:0px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    background-color: rgba(15, 24, 33,0.5);
    width:100vw;
    height:100vh;
    z-index:1000;
   

}

.update-player-email-and-phone-save-box-container {

    height:16.8125em;
    width:45.375em;
    background-color: white;
    display:flex;
    flex-direction:column;
    align-items: center;
    padding-top: 3em;
    padding-bottom: 2em;
    border-radius: 5px;

}
.update-player-email-and-phone-save-box-message{
    font-family: 'Barlow-SemiBold','Barlow';
    font-weight: 600;
    color: rgb(0, 0, 0);
    font-size: 1.375em;
    font-weight: 600;    
    letter-spacing: -0.51px;
}
.update-player-email-and-phone-save-box-button-yes {

    background: rgb(5, 159, 159);
    margin-top: 3em;
    border-radius: 5px;
    border: 1px solid rgb(105, 219, 219);
    height:3em;
    width:13.75em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:white;
    
}
.update-player-email-and-phone-save-button-yes-text{
    font-size:1.1875em;
    
}
.player-email-and-phone-message-box-button-no{
    margin-top: 1.5em;
    color: rgb(30, 75, 117);
    font-size: 1.125em;
    font-weight: 600;
    letter-spacing: -0.43px;

}
.update-player-email-and-phone-confirmation-container {

    height: 19.125em;
    width:45.375em;
    background-color: white;
    display:flex;
    flex-direction:column;
    align-items: center;
    padding: 3.0625em 2em;
    border-radius: 5px;

}
.update-player-email-and-phone-confirmation-text{

    color: rgb(0, 0, 0);
    font-size: 1.375em;
    font-weight: 600;    
    letter-spacing: -0.51px;
    font-family: 'Barlow-Bold','Barlow';
}

.update-player-email-and-phone-confirmation-message{

    color: rgb(0, 0, 0);
    text-align: center;
    font-family: 'Barlow-Regular','Barlow';
    font-size: 1em;
    font-weight: normal;
    letter-spacing: -0.39px;
}

.update-player-email-and-phone-confirmation-button-close {

    background: rgb(5, 159, 159);
    margin-top: 3em;
    border-radius: 5px;
    border: 1px solid rgb(105, 219, 219);
    height:3em;
    width:13.75em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:white;
    
}
.update-player-email-and-phone-confirmation-close-text{
    font-size:1.1875em;
    
}