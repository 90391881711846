$font-family: 'Barlow-SemiBold', 'Barlow';
.future-reissue-payment-details-title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-family;
  justify-content: space-between;
  background: rgb(22, 58, 95);
  border-radius: 0.125em;
  min-height: 4.625em;
  padding: 0 1em;
  margin-right: 1em;

  .title {
    font-size: 2em;
    font-family: $font-family;
    font-weight: 600;
    letter-spacing: -0.043125em;
  }

  .buttons-container {
    display: flex;
    column-gap: 0.5em;
    align-items: center;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.5em;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.02125em;
    width: 11.8125em;
    height: 2.75em;
    border-radius: 0.3125em;
    border-color: white;
    background: #1e4b75;
    border: 0.09375em solid #517997;
  }

  .button-label {
    display: flex;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.02125em;
    color: white;
  }
}
