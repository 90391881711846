@import '../../../components/PromotionsComponents/promotions-mixins.scss';

.promotional-messages-page {
  @include page;

  &__content {
    background-color: #163a5f;
    padding: 16px 8px 0px 8px;
    border-radius: 5px;
  }

  &-header {
    @include page-header;

    &__title {
      @include page-title;
    }

    &__create-button {
      @include create-button;
    }
  }
}
