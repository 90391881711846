.section-subtitle {
  display: flex;
  width: fit-content;
  text-wrap: nowrap;
  align-items: center;

  &__action-button {
    padding: 10.5px 16.5px;
    margin-left: 15.5px;
    border-radius: 5px;
    border: 2px solid #517997;
    background: #1e4b75;
    min-width: 130px;
  }
}

.winners-management-tier-ui {
  &__no-winners {
    color: white;
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-style: italic;
    text-align: center;
  }
}
