.payment_details_payment_message {
  margin-right: 1em;
  a {
    text-decoration: underline;
    text-decoration: italic;
    color: #059f9f;
    &:hover {
      color: white;
    }
  }
}
