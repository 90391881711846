@import '../../../mixins.scss';

.date-and-time-input {
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  &__label {
    @include label-text;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  &__optional-label {
    @include label-text;
    font-weight: 400;
    margin-left: 4px;
  }

  &__content {
    @include field-text;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 12px;
    height: 40px;

    &--grey {
      background: #d8d8d8;
      color: black;

      &:disabled {
        opacity: 1;
      }
    }
  }
}
