$bold-font-family: 'Barlow-SemiBold', 'Barlow';
$default-font-family: 'Barlow-Regular', 'Barlow';
  .w2g-review-reissue-title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    justify-content: space-between;
    background: rgb(22, 58, 95);
    border-radius: 0.125em;
    min-height: 4.625em;
    padding-right: 1em;
    padding-left: 1em;
    color: white;
  
    .title {
      font-size: 2em;
      font-family: $bold-font-family;
      font-weight: 600;
      letter-spacing: -0.043125em;
    }
  
    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 0.5em;
      font-size: 1em;
      font-family: $bold-font-family;
      font-weight: 600;
      text-align: center;
      letter-spacing: -0.02125em;
      width: 11.8125em;
      height: 2.75em;
      border-radius: 0.3125em;
    }
  
    .buttons-container {
      display: flex;
      column-gap: 0.5em;
  
      .reissue-button-enabled,
      .reissue-button-disabled {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 11.8125em;
        height: 2.75em;
        background: rgb(5, 159, 159);
        border: 0.09375em solid rgb(105, 219, 219);
        border-radius: 0.3125em;
      }
  
      .reissue-button-enabled {
        cursor: pointer;
      }
  
      .reissue-button-disabled {
        cursor: not-allowed;
        opacity: 50%;
      }
    }
  }

.w2g-review-reissue-form-container {
  border-radius: 0.3125em;
  width: 50%;
  padding-bottom: 1em;
  color: white;
  background: rgb(22, 58, 95);

  .w2g-review-reissue-form-header {
    padding: 1em 1em 0;

    h4 {
      font-size: 22px;
      font-weight: 600;
    }

    p {
      font-size: 18px;
      font-weight: normal;
    }
  }

  .w2g-review-reissue-form {
    display: grid;
    position: relative;
    width: 100%;
    min-height: inherit;
    height: auto;
    border-radius: 0.3125em;
    align-items: flex-start;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    padding: 0 1em 1.5em 1em;
    grid-column-gap: 0.4em;
    grid-row-gap: 1em;

    .title-2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-column: 1 / span 2;
      color: rgb(255, 255, 255);
      font-size: 1.25em;
      font-family: $bold-font-family;
      font-weight: 800;
      letter-spacing: -0.029375em;
    }
  }
}

.w2g-review-reissue-confirmation-ui {
  width: 40em;
  border-radius: 5px;
  background-color: white;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  header {
    font-weight: 600;
    font-size: 1.4em;
    font-family: $bold-font-family;
    margin-bottom: 1.5em;
    color: black;
  }

  label {
    margin: 0;
    font-weight: 400;
    line-height: 1em;
    padding: 0 0.5em;
    font-family: $default-font-family;
    * {
      padding-left: 0.25em;
    }
  }
  .go-back-link {
    font-family: $bold-font-family;
    font-weight: 600;
    color: black;
  }

  button {
    font-size: 1.25em;
    width: 11em;
    height: 2.4em;
    font-family: $bold-font-family;

    &.add-to-queue-button {
      background-color: #059f9f;
      border: solid 1.5px #69dbdb;
      border-radius: 5px;
      color: white;
      margin-bottom: 1em;
    }

    &.reprint-immediately-button {
      background-color: #1e4b75;
      border: solid 1.5px #517997;
      border-radius: 5px;
      color: white;
      margin-bottom: 0.8em;
    }
  }
}