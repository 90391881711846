@import '../../../mixins.scss';

.side-bar-text-area {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;

  &__label {
    @include label-text;
    margin-bottom: 4px;
  }

  &__text {
    @include field-text;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    padding: 8px 6px 0px 6px;
    line-height: 18px;
    overflow-y: auto;
    resize: none;

    &--grey {
      background: #d8d8d8;
      color: black;

      &:disabled {
        opacity: 1;
      }
    }
  }
}
