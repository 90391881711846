@import '../../../../../mixins.scss';

.promotion-drawing-details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  max-height: 650px;
  overflow: auto;

  color: black;
  font-family: 'Barlow';
  font-weight: lighter;

  &__title-label {
    margin: 0 12px 0 0;
    padding: 0;
    font-family: 'Barlow-Semibold', 'Barlow';
    font-weight: 600;
    font-size: 18px;
    color: black;
  }

  &__error {
    color: red;
    padding-bottom: 20px;
    text-align: center;
  }

  &__progress-container {
    padding: 20px 0;
  }

  &__close-button {
    color: white;
    background: rgb(5, 159, 159);
    border-radius: 5px;
    border: 1.5px solid rgb(105, 219, 219);
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.45px;
    padding: 8px 81px;
    width: fit-content;
    white-space: nowrap;
    margin-bottom: 20px;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
  }

  &__details {
    max-height: 600px;
    overflow: auto;
    margin-bottom: 16px;
  }
}
