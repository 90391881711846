@import '../../../mixins.scss';

.custom-dropdown {
  position: relative;

  &__label {
    @include label-text;
    margin-bottom: 4px;
  }

  &__dropdown {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
    appearance: none;
    padding-right: 2rem;
    padding: 12px 50px 12px 8px;
    width: 100%;
    background: white;
    background-repeat: no-repeat;
    background-position: right 8px top 50%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.343px;

    &--disabled {
      cursor: not-allowed;
    }

    &--grey {
      background: #d8d8d8;
      background-repeat: no-repeat;
      background-position: right 8px top 50%;
      color: black;

      &:disabled {
        opacity: 1;
      }
    }
  }
}
