.promotions-reporting-page-ui {
  padding: 16px;
  width: 100%;
  .back-button-container {
    // Overriding styling for back button component
    margin: 0;
    font-size: 20px;
  }
  &__header {
    background-color: #163a5f;
    border-radius: 2px;
    color: #fff;
    padding: 16px;
    margin: 16px 0;
    font-family: Barlow;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.686px;
  }
  &__tab-container {
    padding: 0 16px;
    width: 100%;
    display: flex;
    margin-top: 16px;
    border-bottom: 3px solid white;
  }
  &__tab {
    color: #fff;
    width: 160px;
    text-align: center;
    background-color: #183e66;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 0;
    letter-spacing: -0.386px;
    margin-right: 4px;
    border-radius: 5px 5px 0px 0px;
  }
  &--tab-selected {
    color: #183e66;
    background-color: #fff;
  }
  &__content {
    margin-top: 20px;
  }
}
