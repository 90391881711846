@import '../../mixins.scss';

@property --sizePx {
  syntax: '<number>px';
  inherits: true;
  initial-value: '';
}

@property --leftOffsetPx {
  syntax: '<number>px';
  inherits: true;
  initial-value: '';
}

@mixin arrow-default {
  content: '';
  position: absolute;
  left: 15px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.generic-tool-tip {
  --sizePx: 20px;
  width: fit-content;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: var(--sizePx);
    height: var(--sizePx);
  }

  &__pop-up {
    border: 2px solid rgb(81, 121, 151);
    background-color: white;
    visibility: hidden;
    border-radius: 5px;

    position: absolute;

    display: flex;
    flex-direction: column;
    z-index: 2;
    padding: 10px;

    &--top {
      bottom: calc(100% + var(--sizePx) / 2);
      left: var(--leftOffsetPx);
    }

    &--bottom {
      top: calc(100% + var(--sizePx) / 2);
      left: var(--leftOffsetPx);
    }

    &--no-border {
      border: none;
    }

    &--shadow {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    }

    &--bottom-arrow {
      ::after {
        @include arrow-default;
        top: 100%;
      }
    }
    &--top-arrow {
      ::after {
        @include arrow-default;
        bottom: 100%;
      }
    }
  }
}

.generic-tool-tip:hover .generic-tool-tip__pop-up {
  visibility: visible;
}
