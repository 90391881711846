$font-family: 'Barlow-SemiBold', 'Barlow';
.check-run-queue-future-payment-search-and-filter {
  label {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-family: $font-family;
    font-weight: 600;
    letter-spacing: -0.01625em;
    line-height: 1.125em;
  }

  .filter-section {
    padding: 1em;
    width: 100%;
    background: rgb(20, 52, 85);

    .filter-control-section {
      display: none;
      margin-top: 1em;
      &.open {
        display: flex;
      }

      .filter-control {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      select {
        /* replace default styling( to remove arrow) */
        appearance: none;
        border-radius: 4px;
        border: 1px solid rgb(107, 107, 107);
        box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
        font-size: 0.8em;
        padding: 0 0.5em;
        height: 100%;
        width: 17.5em;
        padding-right: 2.5em;
        text-overflow: ellipsis;
        box-sizing: content-box;
      }

      .select-container {
        position: relative;
        height: 2.5em;
      }

      .select-arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        height: 100%;
        width: 2em;
        &::after {
          --arrow-size: 0.56em;
          content: '';
          position: absolute;

          /* arrow creation */
          border-left: var(--arrow-size) solid transparent;
          border-right: var(--arrow-size) solid transparent;
          border-top: var(--arrow-size) solid black;

          /* arrow position */
          top: 38%;
        }
      }
    }

    .filter-header {
      header {
        font-weight: 600;
        letter-spacing: -0.6px;
        font-family: $font-family;
        font-size: 1.75em;
      }

      .chevron {
        color: #059f9f;
        margin: 0 0.25em;
      }
    }
  }

  .search-section {
    padding: 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .search-control-section {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.25em;
      .search-control {
        display: flex;
        margin: 0 5px;
        flex-direction: column;

        .text-box {
          width: 16.5em;
          height: 2.5em;
          background: rgb(255, 255, 255);
          border: 0.0625em solid rgb(107, 107, 107);
          box-shadow: inset 0em 0.0625em 0.1875em 0em rgba(15, 24, 33, 0.2);
          border-radius: 0.25em;
        }
      }
    }

    .search-action-section {
      flex-shrink: 0;

      button {
        height: 2.5em;
        width: 6em;
        border-radius: 5px;
      }

      .search-button {
        background: rgb(5, 159, 159);
        border: 1.5px solid rgb(105, 219, 219);
        margin-right: 0.5em;
      }
      .clear-button {
        background: rgb(30, 75, 117);
        border-radius: 5px;
        border: 1.5px solid rgb(81, 121, 151);
      }
    }
  }
}
