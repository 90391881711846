$bold-font-family: 'Barlow-SemiBold', 'Barlow';
$default-font-family: 'Barlow-Regular', 'Barlow';

.payment-details-payee-type-modal {
  width: 20em;
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 2em;
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  header {
    font-weight: 800;
    font-size: 2em;
    font-family: $bold-font-family;
  }

  label {
    margin: 0;
    font-weight: 600;
    line-height: 1em;
    padding: 0 0.5em;
    font-family: $default-font-family;
    font-size: 1.25em;
    * {
      padding-left: 0.25em;
    }
  }

  a {
    font-family: $bold-font-family;
    font-weight: 600;
  }

  button {
    font-size: 1.25em;
    width: 11em;
    height: 2.4em;
    font-family: $bold-font-family;
    &.primary-button {
      background-color: #059f9f;
      border: solid 1.5px #69dbdb;
      margin-top: 0.25em;
      border-radius: 5px;
      color: white !important;
      margin-bottom: 0.8em;
    }
  }

  section {
    font-family: $default-font-family;
    letter-spacing: -0.39px;
    text-align: center;
    padding: 0.5em 0;
  }

  .controls {
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
    margin: 1em;

    .control-wrapper {
      display: flex;
      align-items: center;
      input[type='radio'] {
        $size: 1em;
        height: $size;
        width: $size;
        box-sizing: content-box;
      }
    }
  }

  * {
    color: #121921 !important;
  }
}
