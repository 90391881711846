@import '../../../mixins.scss';

.promotion-entry-details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  max-height: 650px;
  overflow: auto;

  color: black;
  font-family: 'Barlow';
  font-weight: lighter;

  &__disabled-notice {
    background: rgb(213, 230, 246);
    border-radius: 2px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 4px;
    box-sizing: border-box;
    font-family: 'Barlow-Semibold', 'Barlow';
    b {
      font-size: 16px;
      font-weight: bold;
    }
    &-copy {
      font-size: 14px;
    }
  }

  &__exclamation {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #ffc75a;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    &-row {
      display: flex;
      align-items: baseline;
    }
    &-label {
      margin: 0 12px 0 0;
      padding: 0;
      font-family: 'Barlow-Semibold', 'Barlow';
      font-weight: 600;
      font-size: 18px;
      color: black;
    }

    &-value {
      margin: 0;
      font-size: 24px;
      font-family: 'Barlow-Semibold', 'Barlow';
    }
  }

  &__no-promotions {
    text-align: center;
    margin: 32px 126px 24px;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
    padding: 0;
  }

  &__error {
    color: red;
    padding-bottom: 20px;
  }

  &__progress-container {
    padding: 20px 0;
  }

  &__delete-button {
    color: white;
    background: rgb(5, 159, 159);
    border-radius: 5px;
    border: 1.5px solid rgb(105, 219, 219);
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.45px;
    padding: 8px 81px;
    width: fit-content;
    white-space: nowrap;
    margin-bottom: 20px;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
  }

  &__back-button {
    @include modal-back-button;
  }

  &__details {
    max-height: 600px;
    overflow: auto;
    margin-bottom: 16px;
  }
}
