.user-icon {
    text-align: right;
    cursor: pointer;
}
.edit-icon {
    cursor: pointer;
}

.delete-popup {
    margin-top: 10%;
    /*margin-left: 50%;*/
    /*z-index: 999;*/
}
.hide-user-popup {
    opacity: 0.5;
}
.user-management {
    /*position: relative;*/
    /*margin-left: 5%;*/
    /*margin-right: 5%;*/
    /*padding-left: 10%;*/
    /*padding-right: 10%;*/

    margin: 0 2px;

    /*top: 1%;*/
    /*right: 1%;*/
    /*left: 1%;*/


}
.user-management-child {
    margin: 0;
    position: relative;
    top: 2px;
    margin-top: 2px;
    /*right: 2%;*/
    /*left: 1%;*/

    right: 10px;
    left: 10px;


    /*margin: 0;*/
    /*position: absolute;*/
    /*top: 35%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*-ms-transform: translate(-50%, -50%);*/
    box-shadow: 0 0 18px #173e67;
    background-color: #173e67;
    //margin-top: 2 px;
    /*top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);*/
    /*box-shadow: 0 0 18px #005288;
    background-color: #005288;*/

}

.modal-content {
    background-color:  #173e67 !important;
    box-shadow: 0 0 18px  #173e67;
}
.user-dropdown, .user-dropdown:focus {
    background-color: #005288;
    color: #FFFFFF;
}

.user-table-header {
    display: flex;
    justify-content: space-between;
    font-family: Montserrat;
    font-weight: 600;
}
.invalid-email {
    color: red;
    margin-top: -20px;
}

/*.fixed_header .my-table {*/
/*    height: 300px;*/
/*    overflow-y: scroll;*/
/*    table-layout:fixed;*/
/*}*/

