@mixin summary-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.471px;

  p {
    margin: 0;
  }
}

@mixin total-tickets {
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.386px;
}

.promotions-hourly-report-summary-ui {
  * {
    font-family: 'Barlow';
    color: white;
  }
  width: 100%;
  max-width: 400px;
  background-color: #163a5f;
  border-radius: 4px;
  padding: 16px;

  &__header {
    margin-bottom: 20px;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.471px;
  }

  &__summary-item-header {
    @include summary-item;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__summary-item-text {
    @include summary-item;
    font-weight: 100;
    margin-top: 4px;
  }

  &__total-tickets-header {
    @include total-tickets;
    font-weight: 100;
    margin: 0px 0px 20px 0px;
  }

  &__total-tickets-text {
    @include total-tickets;
    font-weight: 400;
    margin-left: 7px;
  }
}
