.modal-overlay-container {
  position: fixed;
  top: 0em;
  left: 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 24, 33, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
