$font-family: 'Barlow-SemiBold', 'Barlow';
.check-run-history-table {
  &_primary_button {
    font-family: $font-family;
    background: rgb(5, 159, 159);
    border-radius: 5px;
    border: 1.5px solid rgb(105, 219, 219);
    color: white;
    padding: 0.25em 2em;
  }
}