@import '../../../mixins.scss';

.playslips-step-down-sidebar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;

  &__title {
    font-family: 'Barlow-Regular', 'Barlow';
    font-weight: bold;
    font-size: 22px;
    margin-right: 87px;
  }

  &__close-button {
    @include large-button;
    border: 1.5px solid #517997;
    color: white;
    background-color: #1e4b75;
  }
}
