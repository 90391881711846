$font-family: 'Barlow-SemiBold', 'Barlow';

.reissue-history-container-ui {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em;

  color: rgb(255, 255, 255);

  .reissue-history-title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
    background: rgb(22, 58, 95);
    border-radius: 0.125em;
    min-height: 4.625em;
    padding-right: 1em;
    padding-left: 1em;

    .title {
      font-size: 2em;
      font-family: $font-family;
      font-weight: 600;
      letter-spacing: -0.043125em;
    }

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      column-gap: .5em;
  
      font-size: 1em;
      font-family: $font-family;
      font-weight: 600;
      text-align: center;
      letter-spacing: -0.02125em;
  
      width: 11.8125em;
      height: 2.75em;
      border-radius: 0.3125em;
    }

    .button.blue {
      background: rgb(30, 75, 117);
      border: 0.09375em solid rgb(81, 121, 151);
      box-shadow: 0em 0.0625em 0.125em 0em rgba(23, 62, 103, 0.29);
      border-radius: 0.3125em;
    }
  }

  .reissue-run-history-table {
    &_primary_button {
      font-family: $font-family;
      background: rgb(5, 159, 159);
      border-radius: 5px;
      border: 1.5px solid rgb(105, 219, 219);
      color: white;
      padding: 0.25em 2em;
    }
  }
}