@import '../../mixins.scss';

@keyframes darken {
  from {
    background: none;
  }

  to {
    background: rgba(24, 25, 27, 0.5);
  }
}

.generic-popup-modal {
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  position: fixed;
  z-index: 150;
  background-color: white;

  &--closed {
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
  }
  &--rounded-edges {
    border-radius: 5px;
  }

  &__content {
    display: flex;
  }

  &__overlay {
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: darken;
    position: fixed;
    z-index: 149;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  &__close-button {
    @include exit-button;

    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.generic-popup-modal__close-button:hover {
  background: none;
}
