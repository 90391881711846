.campaigns-list-header {
  background-color: rgb(49, 109, 156);
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  font-size: 16px;
  letter-spacing: -0.34px;
  width: 100%;

  &-cell {
    text-align: left;
    background-color: rgb(49, 109, 156);
    color: white;
    font-family: 'Barlow-Bold', 'Barlow';
    padding: 14px 0px 14px 0px;

    &:first-child {
      border-radius: 5px 0px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 5px 0px 0px;
    }
  }
}
