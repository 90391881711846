.menu-home-icons-container {
  justify-self: center;
  width: 2.625em;
  height: 2.625em;
  margin-bottom: 0.75rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-home-icon {
  width: 1.125em;
  height: 1.125em;
}
.menu-home-icon-text {
  color: rgb(23, 62, 103);
  font-family: 'Barlow-Bold', 'Barlow';
  font-size: 0.5em;
  font-weight: bold;
  letter-spacing: -0.17px;
}
