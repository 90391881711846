.back-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em;

  &--remove-left-margin {
    margin-left: 0;
  }
}

.back-message {
  height: 1.5em;
  color: rgb(255, 255, 255);
  font-size: 1.25em;
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 600;
  letter-spacing: -0.026875em;
}
