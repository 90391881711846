@import '../../mixins.scss';

.radio-button-input {
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  &__label {
    @include label-text;
    margin-bottom: 4px;
  }

  &__optional-label {
    @include label-text;
    font-weight: 400;
  }

  &__content {
    display: flex;

    &__option {
      display: flex;
      align-items: center;

      margin-right: 16px;
    }
    &__label {
      @include field-text;
      margin: 0 0 0 8px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.386px;

      &--grey {
        opacity: 0.5;
      }
    }
    &__input {
      accent-color: black;
    }
  }
}
