@import '../../../../mixins.scss';

.text-preview {
  max-width: 312px;

  &-container {
    display: flex;
    background: rgb(245, 245, 245);
    border-radius: 5px;
    position: relative;
    align-items: center;
    margin-bottom: 8px;

    &__text {
      @include field-text;
      margin: 6px 8px;
    }

    &__remove-button {
      @include exit-button;
      position: relative;
      margin-left: auto;
      margin-right: 6px;
    }
  }
}
