@import '../../../mixins.scss';

.promotion-entry-details-delete-modal {
  &__back-button {
    @include modal-back-button;
  }
  &__confirm-delete {
    text-align: center;
    margin: 48px 126px;
  }
  &__delete-button {
    color: white;
    background: rgb(5, 159, 159);
    border-radius: 5px;
    border: 1.5px solid rgb(105, 219, 219);
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.45px;
    padding: 8px 81px;
    width: fit-content;
    white-space: nowrap;
    margin-bottom: 20px;
    cursor: pointer;
  }
  &__error {
    color: red;
    padding-bottom: 20px;
  }
  &__loading {
    padding-bottom: 20px;
  }
}
