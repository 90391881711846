.campaigns-list {
  width: 100%;
  background: rgb(22, 58, 95);
  color: rgb(255, 255, 255);
  border-collapse: collapse;

  tbody:nth-child(odd) {
    background-color: rgb(20, 52, 85);
  }

  tbody:nth-child(even) {
    background-color: rgb(29, 69, 111);
  }
}
