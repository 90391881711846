
$font: 'Barlow';

.agent-1099-report-page-ui {

  margin: 16px;

  &__header {
    color: #FFF;
    font-family: $font;
    font-size: 32px;
    font-weight: 600;

    width: 100%;
    height: 69.446px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #163A5F;
    margin-bottom: 16px;
    padding: 16px;
  }
}

.sr-table {
  padding-top: 7px;

  .sr-table-display-range {
    padding-top: 16px;
  }

  .sr-table-header {
    color: #FFF;
    font-family: $font;
    font-size: 16px;
    font-weight: 600;
  }

  .sr-table-page-count {
    padding-top: .875em;
    padding-right: 1em;
  }

  .sr-table-body {
    color: #FFF;
    font-family: $font;
    font-size: 16px;
  
    .cta-container {
      margin-right: 16px;
    }
  
    .cta-container button {
      background-color: red;
      border-radius: 5px;
      border: 1.5px solid #69DBDB;
      background: #059F9F;
      padding: 6px 33px;
      margin-right: 16px;
  
      &:disabled {
        border: 1.5px solid #408898;
        background: #0B6A7A;
        color: #8B9AAA
      }
    }

    .sr-table-body-text {
      color: #FFF;
      font-family: $font;
      font-size: 16px;
    }

    .sr-table-no-data-message {
      color: #FFF;
      text-align: center;
      font-family: $font !important;
      font-size: 18px !important;
    }
  }

  .sr-table-footer {
    background-color:#163A5F;
    display: flex;
    justify-content: flex-end;
    padding-top: 60px;
  }
}
