.promotion-drawing-details-table {
  $border-radius: 5px;
  background: rgb(22, 58, 95);
  color: white;
  font-size: 16px;
  border-collapse: collapse;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  margin-top: 24px;
  &--no-load-more {
    border-radius: $border-radius;
  }

  &__load-more {
    color: white;
    border: none;
    font-weight: 600;
    column-span: all;
    cursor: pointer;
    padding: 16px 0 14px;
    margin-bottom: 24px;
    background: #143455;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    &:disabled {
      opacity: 1;
    }
  }

  &__table-container {
    display: flex;
    flex-direction: column;
  }

  tr {
    font-family: 'Barlow';
    font-weight: lighter;
    letter-spacing: -0.51px;

  }

  th,
  td {
    text-align: left;
    padding: 10px 16px 10px 10px;
    min-width: 100px;
    max-width: 400px;
    border: none;

    color: white;
    font-family: 'Barlow';
    letter-spacing: -0.51px;
  }

  thead {
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    white-space: nowrap;

    th {
      text-align: left;
      background-color: rgb(49, 109, 156);
      font-weight: bold;

      &:first-child {
        border-radius: 5px 0px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 5px 0px 0px;
      }
    }
  }

  tbody {
    border-radius: $border-radius;
    td {
      font-weight: lighter;
    }
    tr:nth-child(odd) {
      background-color: rgb(20, 52, 85);
    }

    tr:nth-child(even) {
      background-color: rgb(29, 69, 111);
    }

    tr:last-child {
      border-radius: 0 0 5px 5px;

      td:first-child {
        border-radius: 0 0 0 5px;
      }

      td:last-child {
        border-radius: 0 0 5px 0;
      }
    }
  }
}
