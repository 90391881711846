.winners-management-promotion-list {
  a {
    color: white;
    text-decoration: underline solid;
    font-family: 'Barlow';
  }

  &__empty-message {
    margin: auto;
    color: white;
  }
}
