.image-viewer-controls {
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 5px solid rgb(23, 62, 103);
  padding: 0 12px;
  width: fit-content;
  position: absolute;
  right: 12px;
  bottom: 12px;
  button {
    font-size: 1.7em;
    color: #097969;
    border: none;
    background: none;
    cursor: pointer;
  }
  &__zoom-container {
    display: flex;
  }
}