/* Root Level Styling */
:root {
  --primary-font: 'Barlow-SemiBold', 'Barlow';
  --font-color: #fff;
}

.status-icon {
  height: 100;
  width: 100;
}

.disclaimer {
  font-style: italic;
  font-size: 0.65em;
  font-family: 'Barlow';
  font-weight: 100 !important;
  opacity: 0.7;
  margin-left: 0.5em;
}

/* button specific css */
.annuity-details-btn {
  width: 95%;
  min-height: 2em;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(23, 62, 103, 0.29);
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.34px;
  margin: 0 0.4em;
  color: white;
  background: rgb(30, 75, 117);
  border: 1.5px solid rgb(81, 121, 151);
}

/* Table Specific CSS */
.table {
  width: 100%;
  height: auto;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  padding-right: 0.5em;
  padding-left: 0.5em;
  font-size: 1.1em;
  color: var(--font-color);
  font-family: var(--primary-font);
}

.table-data-header {
  display: flex;
  flex-direction: column;
  min-height: 3em;
  line-height: 2.25em;
  color: var(--primary-clr);
}
.table-data-header p {
  font-size: 0.9em;
}

.table-data-header-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.table-data-grid-header {
  width: 100%;
  height: 3em;
  height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 0% 75% 23%;
  color: var(--primary-clr);
  padding-top: 0.5em;
}

.table-data-grid-body {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 0% 75% 23%;
}

.table-data-grid-body:nth-child(odd) {
  padding: 0.2em 0;
  background-color: rgb(20, 52, 85);
}

.table-data-grid-body:nth-child(even) {
  padding: 0.4em 0;
  background-color: rgb(30, 75, 117);
}

.table-data-grid-two-col {
  display: grid;
  grid-template-columns: 50% 45%;
}

.table-data-label {
  background-color: rgb(49, 109, 156);
  border-radius: 5px 5px 0px 0px;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: -0.34px;
  padding-left: 0.5em;
}

.table-data-body {
  width: 100%;
  height: auto;
}

.table-data-text {
  color: var(--primary-clr);
  width: 95%;
  letter-spacing: -0.39px;
  font-size: 1em;
  word-wrap: break-word;
  padding-left: 0.5em;
}

.status-data-text {
  font-size: 1em;
  display: inline-block;
  width: 5.4em;
}

.ssn-data-text {
  display: inline-block;
  width: 6em;
}

.table-page-label {
  color: var(--primary-clr);
  font-size: 0.875em;
  margin-right: 0.5625rem;
}

.table-data-footer {
  padding: 0.5em;
  border-radius: 0 0 5px 5px;
  width: 100%;
  height: 3.8em;
  background: rgb(20, 52, 85);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: var(--primary-clr);
}
