$font-family: 'Barlow';

@mixin headerText {
  text-align: center;
  font-size: 24px;
  letter-spacing: -0.514px;
}

.promotion-campaign-end-campaign-ui {
  font-family: $font-family;
  color: black;
  padding: 49px 55px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    margin-bottom: 24px;

    h1 {
      @include headerText;
      margin-bottom: 24px;
      font-weight: 600;
    }
    h2 {
      @include headerText;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__content {
    margin-bottom: 24px;
  }

  &__error-message {
    text-align: center;
    font-family: $font-family;
    color: red;
  }

  &__label {
    font-family: $font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.257px;
    margin-bottom: 4px;
  }

  &__button {
    color: white;
    font-family: $font-family;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: -0.45px;

    border-radius: 5px;
    border: 1px solid #69dbdb;
    background: #059f9f;
    padding: 12px 70px;
    width: fit-content;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__back-button {
    margin-top: 24px;
    color: #1e4b75;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.429px;
    border: none;
    background-color: white;
    width: fit-content;
    text-align: center;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

#promotionId {
  width: 313px;
}
