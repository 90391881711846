.reissue-queue-aggregate-totals {
  display: flex;
  flex-direction: column;
  * {
    color: rgb(255, 255, 255);
  }

  .outer-container-title {
    font-size: 1.75em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.6px;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    column-gap: 3.75em;
  }

  .label-value-container {
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    margin-right: 4em;

    &.right-align {
      margin-right: auto;
    }
  }

  .label {
    font-size: 1.125em;
    font-family: 'Barlow-Regular', 'Barlow';
    font-weight: normal;
    letter-spacing: -0.39px;
  }

  .value {
    font-size: 1.125em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.39px;
  }
}
