@import '../../../../mixins.scss';

.new-campaign-sidebar {
  background-color: rgb(255, 255, 255);
  padding: 16px;
  font-family: 'Barlow-Regular', 'Barlow';

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
      font-weight: bold;
      font-size: 22px;
      margin-right: 87px;
    }

    &__dismiss-button {
      @include large-button;
    }
  }

  &__loading {
    padding-top: 222px;
    padding-bottom: 200px;
    display: flex;
    justify-content: center;
  }

  &__form {
    max-width: 312px;
    margin-top: 24px;

    &__start-and-end-date {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__end {
        margin-top: 18px;
      }

      &__dash {
        margin-top: 32px;
      }
    }

    &__artwork {
      margin-bottom: 16px;

      &__link {
        padding: 0;
        color: rgb(0, 14, 238);
        font-size: 12px;
        text-decoration: underline;
        border: none;
        background: none;
        letter-spacing: -0.26px;
        font-weight: lighter;
        cursor: pointer;
      }
    }

    &__error-text {
      font-size: 16px;
      font-style: italic;
      font-family: 'Barlow-Regular', 'Barlow';
      color: red;
    }
  }
}
