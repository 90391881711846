@property --value {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.redemption-pie-chart {
  --value: 0;
  --width: 44px;

  width: var(--width);
  height: var(--width);
  position: relative;

  animation: animate-chart 1s 0.1s both;
}

.redemption-pie-chart:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: conic-gradient(rgb(15, 206, 0) calc(var(--value) * 1%), white 0);
}

@keyframes animate-chart {
  from {
    --value: 0;
  }
}
