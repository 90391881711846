.message-status-container {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 2em;
    height:auto;
    width:97%;
    background: rgb(213, 230, 246);
    border-radius: 2px;
    padding-left: 0.25rem;
    padding-right:0.8125em;
    margin-bottom: 0.5em;

}

.message-status-inner-container {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

.message-status-icon{
    width:1.5em;
    height:1.5em;
}

.message-status-label{
    color: rgb(0, 0, 0);
    font-family: 'Barlow-SemiBold','Barlow';
    font-size: 0.875em;
    font-weight: 600;
    letter-spacing: -0.34px;
}

.message-status-text{
    font-size:  0.75em;
    word-break: break-all;
    font-family: 'Barlow-Regular','Barlow';
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.3px;
}

.message-status-link{
    
    color: rgb(0, 0, 0);
    font-family: 'Barlow-SemiBold','Barlow';
    font-size: 0.625em;
    font-weight: 600;
    letter-spacing: -0.26px;
    margin-top:.2em;
    margin-left: 1.5625rem;
    cursor:pointer;
}