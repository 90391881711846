.add-split-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 100%;
  height: 2.75em;
  border: 1.5px solid rgb(255, 255, 255);
  border-radius: 5px;

  color: rgb(255, 255, 255);
  font-size: 1em;
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.34px;

  &[disabled=true] {
    opacity: 50%;
    cursor: not-allowed;
  }
}