.check-run-queue-pending-list {
  width: 100%;
  height: auto;
  background: rgb(20, 52, 85);
  border-radius: 5px;
  padding-right: 0.5em;
  padding-left: 0.5em;
  font-size: 0.9em;

  &_pending-check-queue-header {
    margin-top: 1em;
    position: relative;
  }

  &_latest-check-run-container {
    position: absolute;
    right: 0;
  }

  &-horizontal-line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid white;
    margin: 1em 0;
    padding: 0;
  }
}
