.promotions-landing-page-ui {
  height: 100%;
  display: flex;
  flex-flow: column;
  color: white;
  padding-left: 32px;
  padding-top: 32px;

  &__header {
    font-size: 32px;
    font-family: 'Barlow-SemiBold', 'Barlow';
    color: white;
    border-bottom: 1px white solid;
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  &__container {
    flex: 1 0 auto;
    background-color: #1d456f;
    display: flex;
    flex-direction: column;
    align-items: center;
    :first-child {
      margin-top: 10em;
    }
  }

  &__nav-button {
    text-align: center;
    width: 280px;
    padding: 20px 0px;
    border: 1.5px solid #517997;
    border-radius: 5px;
    background-color: #1e4b75;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.343px;
    margin: 0.5em;

    // There's global styling on <a> tags that makes this necessary.
    color: white !important;
    text-decoration: none !important;
  }
}
