$font-bold: 'Barlow-SemiBold', 'Barlow';
$font-regular: 'Barlow-Regular', 'Barlow';
.payment-recipient-details {
  letter-spacing: 0.25px;
  .row-container-title {
    color: rgb(255, 255, 255);
    font-family: $font-bold;
    font-size: 1.15em;
    font-weight: 600;
    letter-spacing: 0.25px;
    grid-column: 1 / -1;
  }

  .recipient-details-container {
    display: grid;
    border-radius: 0.3125em;
    grid-template-columns: 15% 25% 15% 45%;
    color: white;
    row-gap: 1em;
    column-gap: 0.5em;
    text-align: left;

    .column-1 {
      grid-column: 1;
      font-family: $font-regular;
    }

    .column-2 {
      grid-column: 2;
      font-family: $font-bold;
      font-weight: 600;
    }

    .column-3 {
      grid-column: 3;
      font-family: $font-regular;
    }

    .column-4 {
      grid-column: 4;
      font-family: $font-bold;
      font-weight: 600;
    }
  }

  .demographic-details-container {
    display: grid;
    border-radius: 0.3125em;
    grid-template-columns: 15% 25% 15% 45%;
    color: white;
    margin-top: 1.5em;
    row-gap: 1em;
    column-gap: 0.5em;
    text-align: left;

    .column-1 {
      grid-column: 1;
      font-family: $font-regular;
    }

    .column-2 {
      grid-column: 2;
      font-family: $font-bold;
      font-weight: 600;
    }

    .column-3 {
      grid-column: 3;
      font-family: $font-regular;
    }

    .column-4 {
      grid-column: 4;
      font-family: $font-bold;
      font-weight: 600;
    }
  }
}
