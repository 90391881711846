$module-navy: #163a5f;
$background-navy: #1e4b75;
.year-end-reporting-page {
  * {
    font-family: 'Barlow';
  }
  background-color: $background-navy;
  color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100vh - 4em);
  padding: 0px 16px 16px 16px;

  &__header {
    background-color: $module-navy;
    font-size: 32px;
    font-weight: 600;
    padding: 16px;
    letter-spacing: -0.686px;
  }
  &__content {
    background-color: $module-navy;
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: auto;
    margin-top: 16px;
    flex-grow: 1;
    align-items: center;
    justify-content: start;
    padding: 280px;
  }

  &__link {
    font-family: 'Barlow';
    color: white;
    background-color: $background-navy;
    width: 280px;
    height: 64px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.343px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
