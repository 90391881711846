.delete-5754-split-confirmation-container {
  position: fixed;
  top: 0em;
  left: 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 24, 33, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  .wrapper {
    height: 16.8125em;
    width: 45.375em;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
    padding-bottom: 2em;
    border-radius: 0.3125em;
  }

  .message {
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    color: rgb(0, 0, 0);
    font-size: 1.375em;
    font-weight: 600;
    letter-spacing: -0.031875em;
  }

  .delete-button {
    background: #d10000;
    margin-top: 3em;
    border-radius: 0.3125em;
    border: 0.0625em solid #db4a4a;
    height: 3em;
    width: 13.75em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.1875em;
  }

  .go-back-button {
    margin-top: 1.5em;
    color: rgb(30, 75, 117);
    font-size: 1.125em;
    font-weight: 600;
    letter-spacing: -0.026875em;
  }
}