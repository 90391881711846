@import '../../../../mixins.scss';

.campaign-sidebar-barcode-details {
  margin: 22px 0 0 0;

  & > hr {
    @include divider-line;
  }

  &__disclaimer {
    max-width: 312px;
    background: rgb(239, 246, 255);
    border-radius: 5px;
    margin-top: 22px;
    padding: 16px;
    display: flex;

    &__icon {
      width: 15px;
      margin-right: 8px;
    }

    &__text {
      margin: 0;
      font-size: 14px;
      max-width: 256px;
      letter-spacing: -0.3px;
    }
  }
  &__field {
    margin: 16px 0 0 0;

    & > hr {
      @include divider-line;
      height: 1px;
      margin: 12px 0;
    }
    &__data {
      display: flex;
      margin-bottom: 4px;
    }
    &__title {
      @include label-text;
      margin: 0 0 4px 0;
      display: flex;
    }
    &__icon {
      max-width: 16px;
      margin-right: 6px;
    }
    &__text {
      @include field-text;
      max-width: 290px;
      overflow-wrap: break-word;
      margin: 0;
    }
    &__error {
      padding: 8px 0 8px 8px;
      margin: 4px 0 12px 0;
      width: 312px;
      height: 32px;
      background: rgb(219, 74, 74);
      color: white;
      font-size: 14px;
      font-family: 'Barlow-Semibold', 'Barlow';
      font-weight: 600;
      letter-spacing: -0.3px;
      display: flex;
      align-items: center;
    }

    &__loading {
      margin: 16px 4px;
    }

    &__tool-tip {
      width: 15px;
      margin-left: 5px;

      &__title {
        @include label-text;
        font-size: 12px;
      }

      &__message {
        @include field-text;
        font-size: 14px;
      }
      &__highlighted {
        @include field-text;
        font-size: 14px;
        padding: 0 2px;
        background-color: lightgray;
        border-radius: 4px;
      }
    }
  }
}
