@import '../../../mixins.scss';

.async-container {
  &__error-container {
    @include centered;
    padding: 10px;
    font-family: 'Barlow-Bold', 'Barlow';
    flex-direction: column;

    &__label {
      font-family: 'Barlow-Regular', 'Barlow';
      font-weight: bold;
      font-size: 20px;
      color: black;
      margin-bottom: 4px;
    }

    &__text {
      max-width: 340px;

      margin: 0 8px 16px 8px;
      font-family: 'Barlow-Regular', 'Barlow';
      color: black;
      font-size: 14px;
    }

    &__back-button {
      @include large-button;
      color: white;
      background-color: rgb(30, 75, 117);
    }
  }

  &__progress-container {
    @include centered;
    padding: 10px;
  }

  &--fitted {
    // Opt for a percentage of the parent instead of the viewport
    height: 100%;
    width: 100%;
  }
}
