.claim-container {
    margin: 2px 0;
    position: relative;
    color: #FFFFFF;
    font-size: 13px;
}
.claim-panel {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #173E67;
    border-radius: 4px;
    margin-bottom: 10px;
}
.claim-top-row {
    display: flex;
    justify-content: space-between;
}
.claim-top-column {
    word-break: break-all;
}
.big-column {
    width: 17%;
}

.claim-container .claim-link {
    color: #FFFFFF;
    cursor: pointer !important;
    border-bottom: 1px solid #FFFFFF;
}
.claim-container .no-data-message {
    font-size: 1rem;
}
.currency-column:before {
    content: '$';
}

.warning-message {
    color: #ff0303;
    font-size: 13px;
    margin-top: -25px !important;
    margin-left: 7px;
    margin-bottom: 10px;
}

.account-type-label {
    color: #ddecef;
    font-size: 14px;
}
.account-select {
    font-size: 14px !important;
}

.claim-container .view-pdf-button {
    float: right;
    background-color: #173E67;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.claim-container .view-sig-button {
    float: left;
    background-color: #173E67;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.claim-container .resubmit-button {
    float: right;
    background-color: #173E67;
    font-size: 13px;
    color: #FFFFFF;
    /*position: absolute;*/
    /*right: 35px;*/
    margin-right: 7px;
    bottom: 0;
}

.button-test {
    margin-top: 10px;
    justify-content: center;
    min-height: 30px;
    min-width: 110px;
    background-color: #1E4B75 !important;
    border-color: #1E4B75 !important;
    color: #FFFFFF;
    align-items: center;
    margin-right: 10px;
    position: absolute;
    right: 25px;
    bottom: 0;
    box-shadow: 0 0 2px #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
}
.button-test:hover {
    background-color: #359FB1 !important;
    border-color: #359FB1 !important;
}