$font-family: 'Barlow-SemiBold', 'Barlow';

.create-5754-split-gross-payment-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: .2em;
  padding-left: .2em;
  padding-right: 0.3em;

  .gross-amount-header {
    margin-bottom: 0.1875em;
    color: white;
    font-size: 0.625em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .header-label {
      width: 50%;
      padding: 0;
      margin: 0;
    }
  }

  .input {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    
    $border-width: 0.0625em;
    .dollar-sign {
      background-color: #FFFFFF;
      color: black;
      height: 2.5em;
      display: flex;
      align-items: center;
      font-weight: 400;
      border-top-left-radius: 0.25em;
      border-bottom-left-radius: 0.25em;
      border-top: $border-width solid rgb(107, 107, 107);
      border-left: $border-width solid rgb(107, 107, 107);
      border-bottom: $border-width solid rgb(107, 107, 107);
      padding: 0 .5em;
    }

    .input-box {
      height: 2.5em;
      width: 99.5%;
      background: rgb(255, 255, 255);
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      border-top: $border-width solid rgb(107, 107, 107);
      border-right: $border-width solid rgb(107, 107, 107);
      border-bottom: $border-width solid rgb(107, 107, 107);
      border-left: 0;
      // border: $border-width solid rgb(107, 107, 107);
      // box-shadow: inset 0em 0.0625em 0.1875em 0em rgba(15, 24, 33, 0.2);
      padding: .5em;
      appearance: auto; /*To display the small down-arrow on drop-down list*/
    }
  }
  

  .gross-payment-tooltip {
    display: flex;
    align-items: center;
    font-family: $font-family;
    letter-spacing: -0.5px;
    font-size: 1.5em;
    font-weight: 200;

    .container {
      margin-left: 0;
      padding-left: 15px;
      position: relative;
      line-height: 12px;
      &:hover {
        .tooltip-message-container {
          visibility: visible;
        }
      }

      .tooltip-message-container {
        background-color: white;
        color: black;
        --width: 15em;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        visibility: hidden;
        line-height: 1em;
        margin-top: 10px;
        padding: 0.8em;
        display: block;
        position: absolute;
        top: 100%;
        width: var(--width);
        left: 50%;
        background-color: white;
        border-radius: 5px;
        z-index: 1000;
        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent white transparent;
        }
      }
    }
  }

  .gross-payment-error-container {
    background-color: #db4a4a;
    width: 46.5%;
    top: 96.5%;
    border-radius: 5px;
    padding: .5em 0 .5em .8em;
    display: block;
    position: absolute;

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      margin-left: -55%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #db4a4a transparent;
    }
  }
}