@import '../../../mixins.scss';

.side-bar-text-input {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;

  &__label {
    @include label-text;
    margin-bottom: 4px;
    display: flex;
  }

  &__input-container {
    display: flex;
    align-items: center;
  }

  &__input {
    @include field-text;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 12px;
    width: 100%;

    &--grey {
      background: #d8d8d8;
      color: black;

      &:disabled {
        opacity: 1;
      }
    }

    &__appended-text {
      margin-left: 16px;
    }

    &__remove-button-container {
      margin-left: 16px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }

    &__remove-button {
      display: flex;
      padding: 0;
    }
  }
}
