$module-navy: #163a5f;
$background-navy: #1e4b75;
.agent-1099-print-details-page-ui {
  * {
    font-family: 'Barlow';
  }
  .back-button-container {
    margin-bottom: 8px;
  }
  background-color: $background-navy;
  margin: 16px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 4em);
  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-radius: 2px;
    background-color: $module-navy;
    color: white;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.686px;
  }
  &__content-container {
    background-color: $module-navy;
    flex-grow: 1;
    border-radius: 5px;

    & .generic-table-row {
      padding-left: 12px;
    }

    & .generic-table-row__cell {
      padding: 14px 0px 14px 8px;
    }

    & .generic-table-row__cell:first-child {
      padding: 14px 0px 14px 16px;
    }
  }
  &__header-subtitle {
    font-size: 16px;
    font-weight: 400;
  }
}
