.header-page{
    position:relative;
    top:0;
    left:0;
    width:100%;
    height:3em;
    background-color:white;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.header-page img{
    height:2.07em;
    width:7.0875em;

}