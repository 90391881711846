.create-void-reissue-title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  background: rgb(22, 58, 95);
  border-radius: 0.125em;
  min-height: 4.625em;
  padding-right: 1em;
  padding-left: 1em;
  color: white;

  .title {
    font-size: 2em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.043125em;
  }

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    column-gap: .5em;

    font-size: 1em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.02125em;

    width: 11.8125em;
    height: 2.75em;
    border-radius: 0.3125em;
  }

  .buttons-container {
    display: flex;
    column-gap: .5em;
    
    .submit-void-reissue-button-enabled {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 11.8125em;
      height: 2.75em;
      background: rgb(5, 159, 159);
      border: 0.09375em solid rgb(105, 219, 219);
      border-radius: 0.3125em;
    }

    .submit-void-reissue-button-disabled {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;

      width: 11.8125em;
      height: 2.75em;
      background: rgb(5, 159, 159);
      border: 0.09375em solid rgb(105, 219, 219);
      opacity: 50%;
      border-radius: 0.3125em;
    }
  }
}