.collapsable-section {
  position: relative;
  border-radius: 5px;
  background: #163a5f;
  color: white;
  font-family: 'Barlow';
  padding: 16px 8px;

  &--dark_blue {
    background: #143455;
  }
  &--light_blue {
    background: #ebf6ff;
  }

  &--blue {
    background: #adc5d7;
  }

  &--flat {
    border-radius: 5px 5px 0 0;
  }

  &--small {
    padding: 8px 0;
  }

  &__header {
    position: relative;
    border: none;
    display: flex;
    align-items: center;

    &__button {
      color: white;
      background: none;
      border: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: auto;
      padding: 0;

      &--space-between {
        justify-content: space-between;
      }
    }

    &.open {
      margin-bottom: 8px;
    }

    &__title {
      margin-right: 8px;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &--small {
        color: black;
        font-family: 'Barlow';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.257px;
      }

      &--large {
        margin-left: 8px;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.6px;
      }
    }
    &__subtitle {
      margin-left: 8px;
      margin-right: 12px;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__body {
    transition:
      height 0.4s ease-in-out,
      padding 0s 0.4s ease-in-out,
      opacity 0.4s ease-in-out;
    color: black;
    border-radius: 0 0 5px 5px;
    opacity: 0;

    &--open {
      opacity: 1;
    }
  }
}
