@import '../../../../mixins.scss';

$border-radius: 5px;

.generic-table-row {
  font-size: 16px;
  letter-spacing: -0.39px;
  text-align: left;

  &--error {
    box-shadow: inset 0 0 0 1000px rgba(219, 74, 74, 0.4);
  }

  &__message-bar {
    border-radius: $border-radius;
    margin: 8px 16px 0 16px;
    padding: 4px 8px;
    font-size: 14px;
    font-family: 'Barlow-Semibold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.3px;
    max-height: 24px;
    display: flex;
    align-items: center;

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      display: flex;
    }
  }

  &__cell {
    padding: 0px 8px;
    font-family: 'Barlow';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.343px;

    &:first-child {
      padding: 0px 8px 0px 16px;
    }

    &--bold {
      font-weight: 600;
    }

    &--small {
      font-size: 12px;
      font-weight: 400;
      line-height: 150%; /* 18px */
      letter-spacing: -0.132px;
    }

    &__status {
      padding: 4.5px 8px;
      display: flex;
      align-items: center;
      width: fit-content;
      font-family: 'Barlow';
      background-color: rgb(255, 255, 255);
      color: rgb(18, 25, 33);
      border-radius: 22px;
      font-size: 12px;
      position: relative;

      &__text {
        margin-left: 3.5px;
        font-weight: 600;
      }

      &__circle {
        @include circle-indicator;

        margin-left: 0px;
      }
    }

    &__link {
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.343px;
      text-decoration: underline;
      border: none;
      box-shadow: none;

      background: none;
      color: white;

      &:disabled {
        text-decoration: none;
        opacity: 1;
        font-weight: 400;
      }
    }

    &__action-menu {
      width: 64px;
    }
  }
}
