$font-family: 'Barlow-SemiBold', 'Barlow';
.check-run-reporting-action {
  padding: 1em;
  width: 100%;
  background: rgb(20, 52, 85);

  .action-header {
    color: rgb(255, 255, 255);
    font-size: 28px;
    font-family: $font-family;
    font-weight: 500;
    letter-spacing: -0.6px;
  }

  .select-control {
    display: inline-flex;
    flex-direction: column;
  }
  select {
    /* replace default styling( to remove arrow) */
    appearance: none;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
    font-size: 0.8em;
    padding: 0 0.5em;
    height: 90%;
    width: 14em;
    text-overflow: ellipsis;
    box-sizing: content-box;
    margin-right: 1em;
    cursor: pointer;
  }

  .select-container {
    position: relative;
    height: 2em;
  }

  .select-arrow {
    position: absolute;
    align-items: center;
    top: 0;
    right: 47%;
    display: block;
    height: 100%;
    width: 1em;
    pointer-events: none;
    &::after {
      --arrow-size: 0.5em;
      content: '';
      position: absolute;

      /* arrow creation */
      border-left: var(--arrow-size) solid transparent;
      border-right: var(--arrow-size) solid transparent;
      border-top: var(--arrow-size) solid black;

      /* arrow position */
      top: 35%;
    }
  }

  .select-label {
    font-size: 12px;
    font-weight: lighter;
    margin-bottom: 0.25em;
  }
}
