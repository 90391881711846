@mixin centered {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin float {
  float: left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin circle-indicator {
  margin-left: 6px;
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

@mixin large-button {
  font-size: 22px;
  height: 32px;
  width: 85px;
  border-radius: 5px;
  border: 2px solid rgb(81, 121, 151);
  font-size: 16px;
  text-align: center;
  color: rgb(81, 121, 151);
  background-color: white;
  padding: 0;
  cursor: pointer;
}

@mixin exit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  height: 16px;
  width: 16px;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
}

@mixin label-text {
  font-weight: 600;
  letter-spacing: -0.26px;
  font-size: 12px;
  font-family: 'Barlow-Semibold', 'Barlow';
}

@mixin field-text {
  font-family: 'Barlow-Regular', 'Barlow';
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: -0.34px;
}

@mixin divider-line {
  width: 312px;
  height: 2px;
  background-color: rgb(218, 218, 218);
  border: none;
  margin: 0;
}

@mixin modal-back-button {
  color: rgb(30, 75, 117);
  font-size: 20px;
  font-family: 'Barlow-SemiBold', 'Barlow';
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.43px;
  margin-bottom: 16px;
  cursor: pointer;

  &:active,
  &:focus {
    border: none;
    box-shadow: none;
  }
}

@mixin promotions-page {
  width: 100%;
  background-color: #1e4b75;
  padding: 16px;
  overflow: auto;
}

@mixin promotions-page-header {
  min-height: 70px;
  width: 100%;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

@mixin promotions-page-title {
  font-size: 32px;
  color: white;
  font-family: 'Barlow-Bold', 'Barlow';
  font-weight: bold;
  letter-spacing: -0.69px;
  padding-left: 15px;
}

@mixin large-turquoise-button {
  border-radius: 5px;
  border: 1.5px solid #69dbdb;
  background: #059f9f;
  width: 140px;
  height: 40px;

  color: white;
  text-align: center;
  font-family: 'Barlow';
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.343px;

  cursor: pointer;
}

@mixin large-navy-button {
  border-radius: 5px;
  border: 1.5px solid #316d9c;
  background: #1e4b75;
  width: 140px;
  height: 40px;

  color: white;
  text-align: center;
  font-family: 'Barlow';
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.343px;

  cursor: pointer;
}
