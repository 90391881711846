.future-payment-details-update-page {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1em;
  gap: 1em;

  .future-payment-details-update-title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    justify-content: space-between;
    background: rgb(22, 58, 95);
    border-radius: 0.125em;
    min-height: 4.625em;
    padding-right: 1em;
    padding-left: 1em;
    color: white;
  
    .title {
      font-size: 2em;
      font-family: 'Barlow-SemiBold', 'Barlow';
      font-weight: 600;
      letter-spacing: -0.043125em;
    }
  
    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      column-gap: .5em;
  
      font-size: 1em;
      font-family: 'Barlow-SemiBold', 'Barlow';
      font-weight: 600;
      text-align: center;
      letter-spacing: -0.02125em;
  
      width: 11.8125em;
      height: 2.75em;
      border-radius: 0.3125em;
    }
  
    .buttons-container {
      display: flex;
      column-gap: .5em;

      .future-payment-details-update-title-bar-button {
        width: 7.5625em;
        min-height: 2.25em;
        border-radius: 5px;
        border: 1.5px solid rgb(255, 255, 255);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: 'Barlow-SemiBold','Barlow';
        font-weight: 600;
        letter-spacing: -0.6px;

        &.submit-future-payment-details-update-button-enabled {
          cursor: pointer;
          background: rgb(5, 159, 159);
          border: 1.5px solid rgb(105, 219, 219);
        }
    
        &.submit-future-payment-details-update-button-disabled {
          cursor: not-allowed;
          background: rgb(5, 159, 159);
          border: 1.5px solid rgb(105, 219, 219);
        }

        &.cancel-future-payment-details-update-button {
          cursor: pointer;
          border: 1.5px solid rgb(255, 255, 255);
        }
      }
    }
  }

  .future-payment-details-update-form-container {
    border-radius: 0.3125em;
    width: 50%;
    padding-bottom: 1em;
    color: white;
    background: rgb(22, 58, 95);

    .future-payment-details-update-form {
      display: grid;
      position: relative;
      width: 100%;
      min-height: inherit;
      height: auto;
      border-radius: 0.3125em;
      align-items: flex-start;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 1.5em;
      grid-column-gap: .4em;
      grid-row-gap: 1em;
  
      .title-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-column: 1 / span 2;
  
        color: rgb(255, 255, 255);
        font-size: 1.25em;
        /*font-size: 1.375em;*/
        font-family: 'Barlow-SemiBold', 'Barlow';
        font-weight: 800;
        letter-spacing: -0.029375em;
      }
    }
  }
}
