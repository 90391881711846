@import '../../../components/PromotionsComponents/promotions-mixins.scss';

.promotion-campaign-builder-page {
  @include page;

  &-header {
    @include page-header;

    &__title {
      @include page-title;
    }
    &__create-button {
      @include create-button;
      margin-right: 8px;
    }
  }

  &__content {
    .generic-table-content {
      border-radius: 0px;
      background-color: #163a5f;
    }
  }

  &__filter-controls {
    background-color: #143455;
    border-radius: 5px 5px 0px 0px;

    &__content {
      padding-left: 8px;
    }

    &__label {
      font-size: 12px;
      color: white;
      font-family: 'Barlow';
    }
  }
  &__status-filter-controls {
    display: flex;
    background-color: #163a5f;
    padding: 16px 16px 8px 16px;
    gap: 8px;
    &__filter-button {
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 18px;
      border-radius: 5px;
      background: #0c2948;
      align-items: center;
      color: white;
      height: 60px;
      gap: 12px;
      padding: 0px 8px;
      display: flex;
    }
  }
}

.promotion-table-div {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  text-wrap: nowrap;

  & > span {
    color: #fff;
    font-family: 'Barlow';
  }

  &--bold {
    font-family: 'Barlow';
    font-weight: 600;
    letter-spacing: -0.343px;
  }

  &--italic {
    font-style: italic;
    font-weight: 400;
    font-family: 'Barlow';
    letter-spacing: -0.343px;
  }
}

#sort-dropdown-promotion-builder {
  width: auto;
}
