.campaigns-list-footer {
  width: 100%;
  background: rgb(22, 58, 95);
  color: rgb(255, 255, 255);
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &__page-label {
    font-family: 'Barlow-Bold', 'Barlow';
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-right: 10px;
  }
}
