.document-image-pop-up-outer-container{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:rgba(0,0,0,0.3);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:10;
    color:black;
    /* font-size:.8vmax; */
    font-size:0.847457627118644vmax;
}
.document-image-pop-up-container{
   
    
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index:200;
    width: 52.875em;
    min-height:47.75em;
    background-color: white;
    padding-top:2.0625em;
    border-radius: 5px;
    
}
.document-image-pop-up-title
{
   color: rgb(0, 0, 0);
   font-size:1.625em;
   font-family: 'Barlow-SemiBold','Barlow';
   letter-spacing: -0.6px;
   font-weight: 600;
}
.document-image-container
{
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;  
   margin-top:1.5625em;
   width: 42.5625em; 
   height: 23.1875em; 
   background-color: #6791B1;
}

.document-image-src-container
{    
    width: 100%;
    height:100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: transperent;
      
}

.document-image-src-controll-container
{
    position: absolute; 
    top: 80%;
    left: 77%;     
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    height: 2em;
    width: 21%;
    font-size: 1.7em;
    z-index: 100;
    color:#097969;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 5px solid rgb(23, 62, 103);
}
.document-image-pop-up-created-at-text{
    margin-top:0.25em;
    font-size:0.75em;
    font-family: 'Barlow-Regular','Barlow';
    font-weight: normal;
}
.document-image-pop-up-actions{
    margin-top: 1.625em;
    border-radius: 5px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    width:100%;
    height: 15.875em;
    background-color: #e9f5f8;
    padding-top:1.5625em;
}
.document-image-pop-up-actions-radio-buttons-container{

       width:100%;
       height:100%;
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content:flex-start;

}
.document-image-pop-up-actions-radio-select-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width:20vw;
    font-size:1.25em;
    font-family: 'Barlow-SemiBold','Barlow';
    font-weight: 600;
}
.document-image-pop-up-actions-radio-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width:20vw;
    margin-top:1.21875em;
    font-size:1.25em;
    font-family: 'Barlow-SemiBold','Barlow';
    font-weight: 600;
    
}
.player-document-info-players-document-details-content-select
{    
    width: 10.625vw;    
    border-radius: 4px;
    margin-right: .4em;
    font-size: 100% !important;
    padding-left:.2em;

}
.player-document-info-players-document-details-content-button-save
{
    margin-top: 1.5em;
    background: rgb(5, 159, 159);
    border-radius: 5px;
    border: 1.5px solid rgb(105, 219, 219);
    width:12.875em;
    height:3em;    
    color:white;   
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.player-document-info-players-document-details-content-button-save-text {
    font-family: 'Barlow-SemiBold','Barlow';
    letter-spacing: -0.6px;
    font-weight: 600;    
    font-size:1.1875em;

}
.player-document-info-players-document-details-content-button-cancel{
    font-family: 'Barlow-SemiBold','Barlow';
    letter-spacing: -0.6px;
    font-weight: 600;
    margin-top:1.25em;
    font-size:1.125em;
    color: rgb(30, 75, 117);
    cursor:pointer;
}
.rotateimg0 {
    -webkit-transform:rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    width:100%;
    height:100%;
  }
.rotateimg90 {
    -webkit-transform:rotate(90deg);
    -moz-transform: rotate(90deg) scale(50%,100%);
    -ms-transform: rotate(90deg) scale(50%,100%);
    -o-transform: rotate(90deg) scale(50%,100%);
    transform: rotate(90deg) scale(50%,100%);
    width:100%;
    height:100%;
  }
.rotateimg180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    width:100%;
    height:100%;
  }
  .rotateimg270 {
    -webkit-transform:rotate(270deg) scale(50%,100%);
    -moz-transform: rotate(270deg) scale(50%,100%);
    -ms-transform: rotate(270deg) scale(50%,100%);
    -o-transform: rotate(270deg) scale(50%,100%);
    transform: rotate(270deg) scale(50%,100%);
    width:100%;
    height:100%;
  }