$font-family: 'Barlow-Regular', 'Barlow';
.payment-tax-basis {
  width: 100%;
  max-width: 50em;
  border-radius: 0.3125em;
  font-family: $font-family;

  .row-container-title {
    color: rgb(255, 255, 255);
    font-family: $font-family;
    font-size: 1.25em;
    font-weight: 600;
    letter-spacing: 0.4px;
  }

  .date {
    font-style: italic;
    grid-column: 2;
    text-align: right;
    font-weight: 400;
  }

  .tax-basis-container {
    background: #0c2948;
    display: grid;
    border-radius: 0.3125em;
    grid-template-columns: 65% 35%;
    row-gap: 0.5em;
    padding: 1em;
    color: white;
    letter-spacing: 0.25px;

    .column-1 {
      grid-column: 1;
    }
    .column-2 {
      grid-column: 2;
      font-family: 'Roboto Mono', monospace;
      letter-spacing: 1px;
      text-align: right;
    }
  }
}
