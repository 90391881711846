@mixin page {
  width: 100%;
  background-color: #1e4b75;
  padding: 16px;
  overflow: auto;
}

@mixin page-header {
  min-height: 70px;
  width: 100%;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

@mixin page-title {
  font-size: 32px;
  color: white;
  font-family: 'Barlow-Bold', 'Barlow';
  font-weight: bold;
  letter-spacing: -0.69px;
  padding-left: 15px;
}

@mixin create-button {
  width: 140px;
  height: 40px;
  background: rgb(5, 159, 159);
  border-radius: 5px;
  border: 1.5px solid rgb(105, 219, 219);
  box-shadow: 0px 1px 2px 0px rgba(23, 62, 103, 0.29);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.34px;
  margin-right: 55px;
  padding: 0.2em 0.45em;
  color: white;
  cursor: pointer;
}
