@import '../../mixins.scss';

.form-file-upload {
  width: 312px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  &--disabled {
    opacity: 50%;
  }

  &__label {
    @include label-text;
    margin-bottom: 4px;
  }

  &__image-dropzone {
    height: 80px;

    border: 1px dashed rgb(107, 107, 107);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 16px;
    }

    &__text {
      font-family: 'Barlow-Regular', 'Barlow';
      font-size: 14px;
      letter-spacing: -0.3px;

      &--highlighted {
        color: rgb(0, 14, 238);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__input {
      display: 'initial';
      opacity: '0';
      position: 'absolute';
      z-index: -1;
    }
  }
}
