@import '../../../../../mixins.scss';

.promotion-campaign-sidebar-form {
  padding: 0px 8px 16px 8px;
  max-width: 344px;

  &__footer {
    display: flex;
    justify-content: flex-end;

    & > button {
      @include large-button;
      border: 1.5px solid #517997;
      color: white;
      background-color: #1e4b75;
      width: 88px;
      margin: 8px 0;
    }
  }

  &__display-header {
    font-family: 'Barlow';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.257px;
    margin-top: 24px;
  }

  &__display-field {
    display: flex;
    justify-content: space-between;

    margin-top: 16px;
    font-family: 'Barlow';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.257px;

    & > span {
      font-weight: 400;
    }
  }

  &__divider {
    border-radius: 8px;
    border: 1px solid #d1d1d1;
  }

  &__drawing-dates {
    display: flex;
    flex-direction: column;

    text-align: right;
    font-family: 'Barlow';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.257px;
  }

  &__form {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    &--horizontal {
      display: flex;
      gap: 16px;
    }

    &__container {
      margin-bottom: 8px;
    }

    &__content {
      overflow: auto;
      height: 85vh;
    }

    &__error-text {
      max-width: 312px;
      text-wrap: pretty;
      font-size: 16px;
      font-style: italic;
      font-family: 'Barlow-Regular', 'Barlow';
      color: red;
    }

    &__form-item {
      margin-bottom: 16px;

      &__label {
        @include label-text;
        display: flex;
        align-items: center;
      }

      &--no-margin {
        margin: 0;
      }
    }

    &__header {
      font-family: 'Barlow';
      font-size: 16px;
      font-weight: 600;
      line-height: 18px; /* 112.5% */
      letter-spacing: -0.257px;
      display: flex;
      align-items: center;
    }

    &__label {
      @include label-text;
      margin-bottom: 4px;
    }

    &__text {
      @include field-text;
      margin: 0 0 16px 1px;
    }

    &__save-button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }

    &__save-button {
      @include large-button;
      border: 1.5px solid #517997;
      color: white;
      background-color: #1e4b75;
    }

    &__remove-button {
      border: none;
      background: none;
      cursor: pointer;
      border: none;
      padding: 0;
      margin-left: 8px;
      display: flex;
    }

    &__add-prize-tier-button-container,
    &__add-game-id-button-container,
    &__add-distribution-button-container,
    &__add-reward-group-button-container {
      display: flex;
      justify-content: flex-end;
    }

    &__add-prize-tier-button,
    &__add-game-id-button,
    &__add-rule-button,
    &__add-reward-group-button {
      @include large-button;
      border: 1.5px solid #517997;
      color: white;
      background-color: #1e4b75;
      width: unset;
      white-space: none;
      padding: 0 12px;
      &--bonus {
        width: 128px;
      }
    }

    &__add-distribution-button {
      @include large-button;
      border: 1.5px solid #517997;
      color: white;
      background-color: #1e4b75;
      width: 156px;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.343px;
    }

    &__separator {
      border: 1px solid #e5e7ea;
    }
  }

  &__promotion-details,
  &__entries-distribution {
    padding: 0 8px;
  }

  &__promotion-reward-group {
    padding: 8px;
    border-radius: 8px;
    background: #adc5d7;
    margin-bottom: 8px;
  }

  &__promotion-drawings,
  &__promotion-reward-groups {
    padding: 8px;
    border-radius: 8px;
    background: #ebf6ff;
    margin-bottom: 16px;
  }

  &__promotion-prize-tiers-container,
  &__entries-distribution-details-container {
    margin-bottom: 16px;
  }

  &__promotion-prize-tiers,
  &__entries-distribution-details {
    padding: 8px;
    border-radius: 8px;
    background: #ebf6ff;

    &-content {
      padding: 8px 0;
    }

    & select {
      margin-top: 4px;
      font-family: 'Barlow';
      padding: 8px 50px 8px 8px;
    }

    &__remove-button {
      border: none;
      background: none;
      cursor: pointer;
      border: none;
      padding: 0;
      margin-left: 8px;
      display: flex;
    }
  }

  &__bonus-drawings {
    padding: 0 8px;
  }

  &__and-separator,
  &__or-separator {
    text-align: center;
    padding: 16px 0;
    margin: 0;
  }

  &__or-separator {
    padding-top: 8px;
  }

  &__promotion-rule-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
}

#promotion-type-dropdown,
#promotion-number-of-drawings-dropdown,
#promotion-number-of-bonus-drawings-dropdown,
#promotion-vendor-dropdown {
  margin-top: 4px;
  font-family: 'Barlow';
  padding: 8px 50px 8px 8px;
}
