.check-run-queue-page {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em;

  color: rgb(255, 255, 255);

  .back-button-container {
    display: flex;
    flex-direction: row;
  }

  .back-message {
    width: 13.8125em;
    height: 1.5em;
    color: rgb(255, 255, 255);
    font-size: 1.25em;
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.026875em;
  }

  .tab-button {
    height: 2.2em;
    font-weight: 500;
    cursor: pointer;
    width: 11em;
  }
}
