.winners-management {
  &-collapsable {
    margin-top: 8px;
  }
  padding: 16px 16px 100px 16px;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Barlow';
  background: #1e4b75;

  &__header {
    background-color: #163a5f;
    border-radius: 5px;
    font-family: 'Barlow';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.686px;
    padding: 16px;
    margin: 16px 0 8px 0;
  }

  &-filter {
    &-collapsable {
      margin-top: 8px;
    }

    padding: 18px 12px;
    width: fit-content;
    border-radius: 5px;
    background: #0c2948;
    display: flex;
    align-items: center;

    color: white;
    font-size: 18px;
    font-weight: lighter;
    letter-spacing: -0.386px;

    &__checkbox-container {
      width: fit-content;
    }
  }

  &-title {
    background-color: #163a5f;
    border-radius: 5px;

    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    padding: 16px;
    margin: 16px 0 8px 0;
    overflow-wrap: break-word;

    display: flex;

    &__text {
      width: 100%;
      margin-right: auto;
    }

    &__buttons {
      display: flex;
      & > button {
        padding: 10.5px 16.5px;
        margin-left: 15.5px;
        border-radius: 5px;
        border: 2px solid #517997;
        background: #1e4b75;
        min-width: 130px;
        height: 40px;

        color: white;
        text-align: center;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.343px;
        text-wrap: nowrap;

        display: flex;
        align-items: center;
      }
    }
  }

  &__tier-message {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }

  &__tier {
    margin-top: 8px;
  }
}
