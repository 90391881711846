.create-5754-split-payee-form-header {
  background-color: rgb(22, 58, 95);
  display: flex;
  padding-left: 0.8em;
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  justify-content: space-between;

  color: white;
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: -0.029375em;
}