.window-container {

    position:fixed;
    top:0px;
    left:0px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    background-color: rgba(15, 24, 33,0.5);
    width:100vw;
    height:100vh;
    z-index:1000;
   

}

.manually-approve-kyc-container {

    height:19.125em;
    width:45.375em;
    background-color: white;
    display:flex;
    flex-direction:column;
    align-items: center;
    padding: 3em 5em;
    border-radius: 5px;

}
.manually-approve-kyc-text{

    color: rgb(0, 0, 0);
    font-size: 1.375em;
    font-weight: 600;    
    letter-spacing: -0.51px;
    font-family: 'Barlow-Bold','Barlow';
}

.manually-approve-kyc-message{

    color: rgb(0, 0, 0);
    text-align: center;
    font-family: 'Barlow-Regular','Barlow';
    font-size: 1em;
    font-weight: normal;
    letter-spacing: -0.39px;
}

.manually-approve-kyc-button-yes {

    background: rgb(5, 159, 159);
    margin-top: 3em;
    border-radius: 5px;
    border: 1px solid rgb(105, 219, 219);
    height:3em;
    width:13.75em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:white;
    
}
.manually-approve-kyc-yes-text{
    font-size:1.1875em;
    
}
