@import '../../../mixins.scss';

.campaigns-list-row {
  font-size: 16px;
  letter-spacing: -0.39px;
  text-align: left;

  &--error {
    box-shadow: inset 0 0 0 1000px rgba(219, 74, 74, 0.4);
  }

  &__message-bar {
    border-radius: 5px;
    margin: 8px 16px 0 16px;
    padding: 4px 8px;
    font-size: 14px;
    font-family: 'Barlow-Semibold', 'Barlow';
    font-weight: 600;
    letter-spacing: -0.3px;
    max-height: 24px;
    display: flex;
    align-items: center;

    &--error {
      background-color: #db4a4a;
    }
    &--pending {
      background-color: #f56f01;
    }
  }

  &__details {
    &__regular-font {
      font-family: 'Barlow-Regular', 'Barlow';
      font-weight: lighter;
    }

    &__semi-bold-font {
      font-family: 'Barlow-Semibold', 'Barlow';
    }

    &__italic-font {
      font-family: 'Barlow-Regular', 'Barlow';
      font-weight: lighter;
      font-style: italic;
    }

    &__date-range {
      width: 92px;
    }

    &__days-left {
      font-size: 14px;
    }

    &__image-container {
      width: 62px;
      margin-left: 16px;
      overflow: hidden;
      padding: 12px 0;
    }

    &__image {
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
      cursor: pointer;
    }

    &__pie-chart {
      height: 44px;
      width: 44px;
    }

    &-redeemed {
      position: relative;
      margin-top: 8px;

      & > div {
        @include float;
      }

      &__indicator {
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background-color: rgb(15, 206, 0);
      }

      &__text {
        margin-left: 12px;
        font-size: 14px;
      }
    }

    &-status {
      padding: 12px 0px 12px 4px;
      width: 68px;
      background-color: rgb(255, 255, 255);
      color: rgb(18, 25, 33);
      border-radius: 22px;
      font-size: 12px;
      position: relative;

      & > div {
        @include float;
      }

      &__text {
        margin-left: 18px;
        font-weight: 600;
      }

      &--pending {
        @include circle-indicator;
        background-color: rgb(255, 235, 0);
      }
      &--active {
        @include circle-indicator;
        background-color: rgb(15, 206, 0);
      }
      &--expired {
        @include circle-indicator;
        background-color: rgb(103, 103, 103);
      }
      &--inactive {
        @include circle-indicator;
        background-color: rgb(103, 103, 103);
      }
    }
  }
}
