.download-btn {
  height: 2em;
  background: rgb(5, 159, 159);
  border: 1.5px solid rgb(105, 219, 219);
  border-radius: 5px;
  padding: 0 1em;
  color: rgb(255, 255, 255);
  font-size: 1em;
  font-family: 'Barlow-SemiBold', 'Barlow';
  letter-spacing: -0.34px;
}
