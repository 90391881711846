$bold-font-family: 'Barlow-SemiBold', 'Barlow';
$default-font-family: 'Barlow-Regular', 'Barlow';

.future_payment_details_update_status {
  width: 30em;
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  header {
    font-weight: 600;
    font-size: 1.4em;
    font-family: $bold-font-family;
    padding-bottom: 0.3em;
  }

  label {
    margin: 0;
    font-weight: 400;
    line-height: 1em;
    padding: 0 0.5em;
    font-family: $default-font-family;
    * {
      padding-left: 0.25em;
    }
  }

  a {
    font-family: $bold-font-family;
    font-weight: 600;
  }

  button {
    font-size: 1.25em;
    width: 11em;
    height: 2.4em;
    font-family: $bold-font-family;
    &.primary-button {
      background-color: #059f9f;
      border: solid 1.5px #69dbdb;
      margin-top: 0.25em;
      border-radius: 5px;
      color: white !important;
      margin-bottom: 0.8em;
    }
  }

  section {
    font-family: $default-font-family;
    letter-spacing: -0.39px;
    text-align: center;
    padding: 0.5em 0;
  }

  .controls {
    display: flex;
    flex-direction: row;

    .control-wrapper {
      display: flex;
      align-items: center;
      margin: 0 0.5em;
      input[type='radio'] {
        $size: 1em;
        height: $size;
        width: $size;
        box-sizing: content-box;
      }
    }
  }

  .notes {
    width: 100%;

    textarea {
      display: none;
      width: 100%;
      height: 7em;
      resize: none;
      font-size: 0.8em;
      &.show {
        display: block;
      }
    }
  }

  * {
    color: #121921 !important;
  }
}
