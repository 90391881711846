
.create-5754-split-payee-form {
  display: grid;
  position: relative;
  width: 100%;
  min-height: inherit;
  height: auto;
  background: rgb(22, 58, 95);
  border-radius: 0.3125em;
  align-items: flex-start;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1.5em;
  grid-column-gap: .4em;
  grid-row-gap: 1em;

  .title-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column: 1 / span 2;

    color: rgb(255, 255, 255);
    font-size: 1.25em;
    /*font-size: 1.375em;*/
    font-family: 'Barlow-SemiBold', 'Barlow';
    font-weight: 800;
    letter-spacing: -0.029375em;
  }
}