.image-viewer {
  position: relative;
  height: 100%;
  width: 100%;
  &__image {
    width: 100%;
    transition: transform 0.3s;
    transform: rotate(var(--rotation-degrees));
  }
}
// third party styling needs specificity override
div.image-viewer {
  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: grab;
  }
  &__transformer {
    cursor: grab;
  }
}
