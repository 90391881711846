.generic-download-button {
  cursor: pointer;
  display: flex;
  border: none;
  background: none;
  align-items: center;
  outline: none;

  &__icon {
    max-width: 14px;
    margin: 4px 4px 0 0;
  }
  &__text {
    color: rgb(0, 14, 238);
    font-size: 16px;
    letter-spacing: -0.34px;
    font-weight: normal;
    text-decoration: underline;
  }

  &:focus {
    border-color: grey;
    border-width: 1px;
  }
}
