.player-promotion-entries {
  margin: 0 0 16px 0;
  background: rgb(22, 58, 95);
  border-radius: 5px;
  padding: 20px 18px 20px 16px;
  color: white;

  &-footer {
    width: 100%;
    background: rgb(22, 58, 95);
    color: rgb(255, 255, 255);
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &__page-label {
      font-family: 'Barlow-Bold', 'Barlow';
      font-size: 16px;
      font-weight: bold;
      text-align: right;
      margin-right: 10px;
    }
  }
}

.player-promotion-entries-table {
  background: rgb(22, 58, 95);
  color: white;
  font-size: 16px;
  border-collapse: collapse;
  border-radius: 5px;

  tr {
    font-family: 'Barlow';
    font-weight: lighter;
    letter-spacing: -0.51px;
  }

  th,
  td {
    text-align: left;
    padding: 10px 16px 10px 10px;
    min-width: 100px;
    max-width: 400px;
    border: none;

    color: white;
    font-family: 'Barlow';
    letter-spacing: -0.3px;

    &.small {
      max-width: 175px;
      width: 175px;
    }

    &.promotion-label {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 500;
      letter-spacing: 0;
    }
  }

  thead {
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    white-space: nowrap;

    th {
      text-align: left;
      background-color: rgb(49, 109, 156);
      font-weight: bold;

      &:first-child {
        border-radius: 5px 0px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 5px 0px 0px;
      }
    }
  }

  tbody {
    border-radius: 0 0 5px 5px;
    td {
      font-weight: lighter;
    }
    tr:nth-child(odd) {
      background-color: rgb(20, 52, 85);
    }

    tr:nth-child(even) {
      background-color: rgb(29, 69, 111);
    }

    tr:last-child {
      border-radius: 0 0 5px 5px;

      td:first-child {
        border-radius: 0 0 0 5px;
      }

      td:last-child {
        border-radius: 0 0 5px 0;
      }
    }
  }
}
