.table-ui {
  width: 100%;
  background-color:#1C456F;
  border-radius: 5px;
  font-size: 0.9em;

  .header-row {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    background-color: #316D9C;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0.5em 0;
    font-weight: 550;
  }

  .data-row {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    padding: 0.5em 0;

    .datum {
      text-align: right;
    }
  }

  .right-column {
    text-align: right;
  }
}