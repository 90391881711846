@import '../../../../mixins.scss';

.edit-campaign-sidebar {
  background-color: rgb(255, 255, 255);
  padding: 16px;
  font-family: 'Barlow-Regular', 'Barlow';

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2px;

    &__title {
      font-weight: bold;
      font-size: 22px;
      margin-right: 87px;
    }

    &__dismiss-button {
      @include large-button;
    }
  }
  &__details {
    margin-bottom: 24px;

    &__field {
      margin-top: 16px;

      &__title {
        @include label-text;
        margin: 0 0 8px 0;
      }
      &__icon {
        max-width: 16px;
        margin-right: 6px;
      }
      &__text {
        @include field-text;
        line-height: 20px;
        max-width: 312px;
        max-height: 200px;

        margin: 0;
        overflow: auto;

        &::-webkit-scrollbar {
          background-color: white;
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: lightgray;
          border-radius: 5px;
        }
      }
    }
  }
}
