@import '../../../../mixins.scss';

.image-preview {
  max-width: 312px;
  display: flex;
  flex-wrap: wrap;
  background: rgb(245, 245, 245);
  border-radius: 5px;
  position: relative;
  margin-bottom: 4px;

  &-image-container {
    position: relative;

    &__image {
      max-width: 44px;
      margin: 8px;
    }

    &__remove-button {
      @include exit-button;
      position: absolute;
      top: 2px;
      right: 0px;
    }
  }

  &__remove-button {
    @include exit-button;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
