.payment-details {
  display: flex;
  flex-direction: column;
  background: rgb(20, 52, 85);
  border-radius: 5px;
  padding: 1em 1em 10em;
  row-gap: 2em;
  width: 80%;

  .check-details-tax-basis {
      display: flex;
      flex-direction: column;
  
      .check-details-row {
        display: flex;
        max-width: 66.9375em;
        justify-content: start;
  
        background: rgb(22, 58, 95);
        border-radius: 5px;
        padding: 1em;
      }
  
      .tax-basis-row {
        display: flex;
        max-width: 66.9375em;
        justify-content: start;
  
        background: rgb(22, 58, 95);
        border-radius: 5px;
        padding: 1em;
      }
    }
}
