.winners-management-form {
  position: relative;
  &-container {
    background-color: #163a5f;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: flex-end;
    padding: 10px 16px 16px 16px;
    width: 100%;
    box-sizing: border-box;
    column-gap: 16px;
  }

  display: flex;
  flex-direction: column;

  &__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.257px;
    margin-bottom: 4px;
  }

  &__select-button {
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.343px;
    padding: 10px 68px;
    border-radius: 5px;
    border: 1.5px solid #69dbdb;
    background: #059f9f;
  }
  &__error {
    width: 95%;
    position: absolute;
    top: 74px;
  }
}
