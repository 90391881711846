@import '../../../mixins.scss';

.number-input {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;

  &__label {
    @include label-text;
    margin-bottom: 4px;
  }

  &__input-container {
    display: flex;
    align-items: center;
  }

  &__input {
    @include field-text;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 12px;

    &--grey {
      background: #d8d8d8;
      color: black;

      &:disabled {
        opacity: 1;
      }
    }
  }

  &__trailing-text {
    font-family: 'Barlow';
    font-size: 16px;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.257px;
    margin-left: 4px;
  }

  &__remove-button {
    margin-left: 16px;
    display: flex;
    padding: 0;
  }
}
