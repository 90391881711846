@import '../../../../../mixins.scss';

.end-campaign-popup {
  max-width: 300px;
  font-family: 'Barlow-Regular', 'Barlow';
  color: rgb(18, 25, 33);

  > header {
    margin: 16px 0 0 16px;
    font-family: 'Barlow-Semibold', 'Barlow';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.51px;
  }

  &__text {
    @include field-text;
    margin: 13px 16px 0px 16px;

    &__error-text {
      margin: 5px 16px 0px 16px;
      font-style: italic;
      font-family: 'Barlow-Regular', 'Barlow';
      color: red;
    }
  }

  &__buttons {
    margin: 24px 16px 16px 0px;
    display: flex;
    justify-content: flex-end;

    &__cancel-button {
      @include large-button;
    }
    &__submit-button {
      @include large-button;
      width: 124px;
      color: white;
      background-color: rgb(30, 75, 117);
      margin-left: 12px;
    }
    &__loading {
      margin-right: 36px;
      margin-top: 10px;
      margin-bottom: 14px;
    }
  }
}
