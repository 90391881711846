.winners-management-landing-page-ui {
  height: calc(100vh - 5em);
  padding: 16px;
  display: flex;
  flex-direction: column;

  &__header {
    font-size: 32px;
    font-family: 'Barlow';
    color: white;
    font-weight: 600;
    padding: 16px;
    background: #163a5f;
  }

  &__container {
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__tab-container {
    padding: 0 16px;
    width: 100%;
    display: flex;
    margin-top: 16px;
    border-bottom: 3px solid white;
  }
  &__tab {
    color: #fff;
    width: 160px;
    text-align: center;
    background-color: #183e66;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 0;
    letter-spacing: -0.386px;
    margin-right: 4px;
    border-radius: 5px 5px 0px 0px;
  }
  &--tab-selected {
    color: #183e66;
    background-color: #fff;
  }
  &__content {
    margin-top: 20px;
  }
}
