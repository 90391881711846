

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Barlow', sans-serif;
    font-family: 'Montserrat', sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  height: 100vh;
  overflow: auto;
 
} 
body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
body *:focus {
  outline: none !important;
  border:2px solid black;   
  box-shadow: 0 0 10px #719ECE;

}
.app-tab-panel{
  overflow: scroll;
}
@font-face {
  font-family: 'Myriad Pro' !important;
  src: local('Myriad Pro'), url('./fonts/MyriadProRegular.otf') format('truetype');
}
@font-face {
  font-family: 'Barlow-Regular' !important;
  src:url("https://dafonttop.com/wp-data/b/416/9416/file/barlow.regular.ttf") format("woff"),
  url("https://dafonttop.com/wp-data/b/416/9416/file/barlow.regular.ttf") format("opentype"),
  url("https://dafonttop.com/wp-data/b/416/9416/file/barlow.regular.ttf") format("truetype");
 
}
@font-face {
  font-family: 'Barlow-SemiBold' !important;
  src:url('./fonts/Barlow/Barlow-SemiBold.ttf') format("woff"),
  url('./fonts/Barlow/Barlow-SemiBold.ttf') format("opentype"),
  url('./fonts/Barlow/Barlow-SemiBold.ttf') format("truetype");
 
}
@font-face {
  font-family: 'Barlow-SemiBoldItalic' !important;
  src:url('./fonts/Barlow/Barlow-SemiBoldItalic.ttf') format("woff"),
  url('./fonts/Barlow/Barlow-SemiBoldItalic.ttf') format("opentype"),
  url('./fonts/Barlow/Barlow-SemiBoldItalic.ttf') format("truetype");
 
}
@font-face {
  font-family: 'Barlow-Medium' !important;
  src:url('./fonts/Barlow/Barlow-Medium.ttf') format("woff"),
  url('./fonts/Barlow/Barlow-Medium.ttf') format("opentype"),
  url('./fonts/Barlow/Barlow-Medium.ttf') format("truetype");
 
}
button{
  font-family: Barlow;
  font-weight: 500;
}

/*@font-face {
  font-family: Barlow !important;
  src: url('./fonts/Barlow/Barlow-Light.ttf') format('ttf');
  font-weight: 300;
}
@font-face {
  font-family: Barlow !important;
  src: url('./fonts/Barlow/Barlow-Regular.ttf') format('ttf');
  font-weight: normal;
}
@font-face {
  font-family: Barlow !important;
  src: url('./fonts/Barlow/Barlow-Medium.ttf') format('ttf');
  font-weight: 500;
}
@font-face {
  font-family: Barlow !important;
  src: url('./fonts/Barlow/Barlow-SemiBold.ttf') format('ttf');
  font-weight: 600;
}
@font-face {
  font-family: Montserrat !important;
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('ttf');
  font-weight: normal;
}
@font-face {
  font-family: Montserrat !important;
  src: local('Montserrat Bold'), url('./fonts/Montserrat/Montserrat-Bold.ttf') format('ttf');
  font-weight: bold;
}*/
body > #root > div {
  height: 100vh;
}


/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
.app-error-message{
  background: #FFC199;
  color: #000000 !important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 5px;
  border-left: 9px solid #dc3545;
  margin-bottom: 1%;
  margin-right: 3%;
  max-width: 300px;
}
.document-image-outer-container > option 
{
   font-size:1.3em;
}
button:disabled:hover {
  cursor: not-allowed;
}